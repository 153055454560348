import { Card } from '@/components/ui/card'

interface DashboardCardProps {
  title: string
  subtitle: string | React.ReactNode
  footer?: React.ReactNode
  numberOf?: number
  icon?: React.ReactNode
  showNumber?: boolean
  height?: string
  footerAlignment?: string
  bgColor?: string
  children?: React.ReactNode
  opacity?: string
  comingSoon?: React.ReactNode
  border?: string
}
const DashboardCard = ({
  title,
  subtitle,
  footer,
  numberOf,
  icon,
  showNumber = false,
  height,
  footerAlignment,
  bgColor,
  children,
  opacity,
  comingSoon,
  border,
}: DashboardCardProps) => {
  return (
    <div className="relative">
      {comingSoon}
      <Card
        className={`${height} ${bgColor} ${opacity} ${border} z-10 flex h-full flex-col justify-between sm:px-8 sm:py-9 md:px-8 md:py-9 lg:px-8 lg:py-9 xl:px-8 xl:py-9 2xl:px-10 2xl:py-9`}
      >
        <div className="flex flex-row justify-between">
          <div className="flex flex-col space-y-2.5">
            <p className="text-xl font-semibold">{title}</p>
            <p className="text-sm">{subtitle}</p>
          </div>
          <div className="flex flex-row items-start justify-end space-x-2">
            <p className="text-4xl font-semibold">{numberOf}</p>
            {icon && <div className="pl-2 pt-0.5">{icon}</div>}
          </div>
        </div>
        <div className={`${showNumber ? 'flex justify-center' : 'w-full'}`}>{children}</div>
        {footer && (
          <p className={`flex underline ${footerAlignment === 'center' ? 'justify-center pt-7' : 'justify-start '}`}>
            {footer}
          </p>
        )}
      </Card>
    </div>
  )
}

export default DashboardCard
