import { initiativesList } from '@/client/backend/api/initiatives/initiatives'
import { sitesResponsesList, sitesRetrieve } from '@/client/backend/api/sites/sites'
import { Response, Site } from '@/client/backend/models'

const initiativeSiteLoader = async ({ params }) => {
  const { siteId } = params

  const initiativesPromise = initiativesList()

  let sitePromise: Promise<Site | null> = Promise.resolve(null)
  let siteResponsesPromise: Promise<Response[] | null> = Promise.resolve(null)

  if (siteId) {
    sitePromise = sitesRetrieve(siteId)
    siteResponsesPromise = sitesResponsesList(siteId)
  }

  const [initiatives, site, siteResponses] = await Promise.all([initiativesPromise, sitePromise, siteResponsesPromise])

  return {
    initiatives,
    site,
    siteResponses,
  }
}
export default initiativeSiteLoader
