import { useEffect, useState } from 'react'
import { cultureMappingsCreate, cultureMappingsList } from '@/client/backend/api/culture-mappings/culture-mappings'
import { pacCulturesList } from '@/client/backend/api/pac-cultures/pac-cultures'
import { phytoCulturesList } from '@/client/backend/api/phyto-cultures/phyto-cultures'
import { CultureMapping, PacCulture, PhytoCulture } from '@/client/backend/models'
import { Merge } from 'lucide-react'

import { AvailableCountry } from '@/types/available-country'
import { STORAGE_KEYS } from '@/lib/constants/local-storage'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Separator } from '@/components/ui/separator'
import { toast } from '@/components/ui/use-toast'
import CountrySelector from '@/components/country-selector'
import CultureMappingsTable from '@/components/culture-mappings-table'
import CulturePacTable from '@/components/culture-pac-table'
import CulturePhytoTable from '@/components/culture-phyto-table'
import { H2, H4 } from '@/components/typography'

const MappingsPage = () => {
  const [phytoCultures, setPhytoCultures] = useState<PhytoCulture[]>([])
  const [pacCultures, setPacCultures] = useState<PacCulture[]>([])
  const [mappingsData, setMappingsData] = useState<CultureMapping[]>([])

  const [selectedPhyto, setSelectedPhyto] = useState<PhytoCulture[]>([])
  const [selectedPac, setSelectedPac] = useState<PacCulture[]>([])
  const [combinations, setCombinations] = useState<Array<[PhytoCulture, PacCulture]>>([])

  const [selectedMappings, setSelectedMappings] = useState<CultureMapping[]>([])

  const [alreadyMappedCombinations, setAlreadyMappedCombinations] = useState<Array<[PhytoCulture, PacCulture]>>([])

  const [country, setCountry] = useState<AvailableCountry>(
    (localStorage.getItem(STORAGE_KEYS.LAST_SELECTED_COUNTRY) as AvailableCountry) ?? AvailableCountry.FR
  )

  const [countryPhyto, setCountryPhyto] = useState<AvailableCountry>(AvailableCountry.FR)

  const [allPhytoCultures, setAllPhytoCultures] = useState<PhytoCulture[]>([])
  const [allPacCultures, setAllPacCultures] = useState<PacCulture[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const [p, pa, m] = await Promise.all([
        phytoCulturesList({
          country: countryPhyto.toUpperCase(),
        }),
        pacCulturesList({
          country: country.toUpperCase(),
        }),
        cultureMappingsList({
          country: country.toUpperCase(),
        }),
      ])

      setPhytoCultures(p)
      setAllPhytoCultures(p)
      setPacCultures(pa)
      setAllPacCultures(pa)
      setMappingsData(m)

      const commonElements = p
        .filter((phyto) => pa.some((pac) => pac.source_culture_name === phyto.name))
        .map((common) => ({
          phyto_id: common.id,
          pac_id: pa.find((pac) => pac.source_culture_name === common.name)?.id,
        }))

      // if commonelements are not already in the mappings, add them to the mappings and the db
      if (commonElements.length > 0) {
        const newMappings = await Promise.all(
          commonElements
            .filter((element) => element.pac_id !== undefined)
            .filter((element) => {
              return !m.some((mapping) => {
                return mapping.phyto_culture.id === element.phyto_id && mapping.pac_culture.id === element.pac_id
              })
            })
            .map((element) =>
              cultureMappingsCreate({
                phyto_culture_id: element.phyto_id,
                pac_culture_id: element.pac_id!,
                validated: false,
              })
            )
        )

        setMappingsData((prevMappings) => [...prevMappings, ...newMappings])
      }
    }
    if (country) {
      fetchData()
    }
  }, [country])

  const generateCombinations = <T1 extends PhytoCulture, T2 extends PacCulture>(
    table1: T1[],
    table2: T2[]
  ): Array<[T1, T2]> => {
    return table1.flatMap((item1) => table2.map((item2) => [item1, item2])) as Array<[T1, T2]>
  }

  useEffect(() => {
    const combinations = generateCombinations<PhytoCulture, PacCulture>(selectedPhyto, selectedPac)
    const uniqueCombinations = combinations.filter(([phyto, pac]) => {
      // Check if the combination already exists in mappingsData
      return !mappingsData.some(
        (existingMapping) => existingMapping.phyto_culture.id === phyto.id && existingMapping.pac_culture.id === pac.id
      )
    })

    const alreadyMapped = combinations.filter(([phyto, pac]) => {
      // Check if the combination already exists in mappingsData
      return mappingsData.some(
        (existingMapping) => existingMapping.phyto_culture.id === phyto.id && existingMapping.pac_culture.id === pac.id
      )
    })

    setAlreadyMappedCombinations(alreadyMapped)

    setCombinations(uniqueCombinations)

    const selectedMappings = mappingsData.filter((mapping) => {
      return (
        selectedPhyto.some((phyto) => phyto.id === mapping.phyto_culture.id) ||
        selectedPac.some((pac) => pac.id === mapping.pac_culture.id)
      )
    })
    setSelectedMappings(selectedMappings)
  }, [selectedPhyto, selectedPac])

  const createMappings = () => {
    combinations.map(async ([phyto, pac]) => {
      try {
        const mapping = await cultureMappingsCreate({
          phyto_culture_id: phyto.id,
          pac_culture_id: pac.id,
          validated: false,
        })
        setMappingsData([...mappingsData, mapping])
      } catch (e) {
        toast({
          title: "Something went wrong, we couldn't create the mapping.",
        })
      }
    })
  }
  const showNoLink = (value: boolean) => {
    const differenceListPhyto = allPhytoCultures.filter(
      (phyto) => !mappingsData.some((mapping) => phyto.id === mapping.phyto_culture.id)
    )

    const phytoList = value ? differenceListPhyto : allPhytoCultures
    setPhytoCultures(phytoList)

    const differenceListPac = pacCultures.filter(
      (pac) => !mappingsData.some((mapping) => pac.id === mapping.pac_culture.id)
    )

    const pacList = value ? differenceListPac : allPacCultures
    setPacCultures(pacList)
  }

  const showNoLinkPac = (value: boolean) => {
    const differenceListPac = pacCultures.filter(
      (pac) => !mappingsData.some((mapping) => pac.id === mapping.pac_culture.id)
    )

    const pacList = value ? differenceListPac : allPacCultures
    setPacCultures(pacList)
  }

  return (
    <>
      <div className="mb-2 flex w-1/3 flex-row justify-end space-x-1 md:flex md:grow">
        <span className="text-muted-foreground">Phyto culture</span>
        <CountrySelector country={countryPhyto} setCountry={setCountryPhyto} countries={[AvailableCountry.FR]} />
      </div>

      <div className="mb-2 flex w-1/3 flex-row justify-end space-x-1 md:flex md:grow">
        <span className="text-muted-foreground">Pac culture</span>
        <CountrySelector
          country={country}
          setCountry={setCountry}
          key={STORAGE_KEYS.LAST_SELECTED_COUNTRY}
          countries={[AvailableCountry.FR, AvailableCountry.BE]}
        />
      </div>

      <div className="flex items-center justify-between">
        <div className="w-3/4">
          <H2 className="mb-4">Phyto cultures</H2>
          <div className="my-2 flex items-center space-x-2">
            <Checkbox id="phyto-check" onCheckedChange={(value) => showNoLink(!!value)} />

            <label
              htmlFor="phyto-check"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Show only unlinked
            </label>
          </div>
          <CulturePhytoTable
            data={phytoCultures}
            setSelectedPhyto={setSelectedPhyto}
            selectedPhyto={selectedPhyto}
            mappings={selectedMappings}
          />
        </div>
        <div className="w-1/4">
          <Dialog>
            <DialogTrigger asChild>
              <Button size="icon" className="mx-4">
                <Merge className="size-3" />
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-md">
              <DialogHeader>
                <DialogTitle>Mappings</DialogTitle>
                <DialogDescription>You are currently mapping those following cultures</DialogDescription>
              </DialogHeader>
              <div>
                <H4>New element to map</H4>

                <Separator className="my-4" />
                {combinations.length > 0 ? (
                  combinations.map(([phyto, pac]) => (
                    <div key={`${phyto.id}-${pac.id}`} className="flex items-center space-x-2">
                      <span>{phyto.name}</span>
                      <Merge className="size-3" />
                      <span>{pac.source_culture_name}</span>
                    </div>
                  ))
                ) : (
                  <span className="text-gray-500">No new element to map</span>
                )}
              </div>

              <div>
                <H4>Already mapped in your selection</H4>

                <Separator className="my-4" />
                {alreadyMappedCombinations.map(([phyto, pac]) => (
                  <div key={`${phyto.id}-${pac.id}`} className="flex items-center space-x-2">
                    <span>{phyto.name}</span>
                    <Merge className="size-3" />
                    <span>{pac.source_culture_name}</span>
                  </div>
                ))}
              </div>
              <DialogFooter>
                <DialogClose asChild>
                  <Button variant="secondary" className="mr-2">
                    Cancel
                  </Button>
                </DialogClose>
                <Button onClick={() => createMappings()}>Save</Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>

        <div className="w-3/4">
          <H2 className="mb-4">Pac cultures</H2>
          <div className="my-2 flex items-center space-x-2">
            <Checkbox id="pac-check" onCheckedChange={(value) => showNoLinkPac(!!value)} />

            <label
              htmlFor="pac-check"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Show only unlinked
            </label>
          </div>
          <CulturePacTable
            data={pacCultures}
            setSelectedPac={setSelectedPac}
            selectedPac={selectedPac}
            mappings={selectedMappings}
          />
        </div>
      </div>
      <div className="mt-8">
        <H2 className="mb-4">Culture mappings</H2>
        <CultureMappingsTable data={mappingsData} />
      </div>
    </>
  )
}

export default MappingsPage
