import { Site } from '@/client/backend/models'
import { Link } from 'react-router-dom'

import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Card } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'

interface NoInitiativesCardProps {
  title: string
  text: string
  text2: string
  text3?: string
  textColor?: string
  textColor2?: string
  textColor3?: string
  button?: React.ReactNode
  site: Site
}

const NoInitiativesCard = ({
  title,
  text,
  text2,
  text3,
  textColor,
  textColor2,
  textColor3,
  button,
  site,
}: NoInitiativesCardProps) => {
  return (
    <Card className="flex flex-col justify-start space-y-36  sm:px-8 sm:py-9 md:px-8 md:py-9 lg:min-h-[435px] lg:w-full lg:px-8 lg:py-9 xl:min-h-[435px] xl:w-full xl:px-8 xl:py-9 2xl:min-h-[435px] 2xl:w-[786px] 2xl:px-10 2xl:py-9">
      <div>
        <div className="flex space-x-2 pb-6 text-xl font-semibold">
          <p className="flex flex-col">{title}</p>
        </div>
        <Separator className="bg-separatorGray" />
      </div>

      <div className="flex flex-col items-center space-y-8">
        <p className="text-xl">
          <span className={`${textColor}`}>{text}</span>
          <span className={`${textColor2}`}>{text2}</span>
          <span className={`${textColor3}`}>{text3}</span>
        </p>
        <div>
          <Link to={getPath(Routes.BEEOINITIATIVE_INITIATIVES_BY_SITE, { params: { siteId: site.id } })}>{button}</Link>
        </div>
      </div>
    </Card>
  )
}

export default NoInitiativesCard
