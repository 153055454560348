import { auditsList } from '@/client/backend/api/audits/audits'
import { sitesList } from '@/client/backend/api/sites/sites'

export default async function loader() {
  const [sites, actionsTakenInitiatives, audits] = await Promise.all([
    sitesList({ include: 'latest_action_taken_date,initiative_score', is_in_initiative: true }),
    sitesList({ include: 'actions-taken', is_in_initiative: true }),
    auditsList(),
  ])

  return {
    sites,
    actionsTakenInitiatives,
    audits,
  }
}
