import { Button } from '@/components/ui/button'

interface CustomOutlineButtonProps {
  icon?: React.ReactNode
  title: string
}
const CustomOutlineButton = ({ icon, title }: CustomOutlineButtonProps) => {
  return (
    <div className="flex items-center gap-x-2">
      <Button variant="outline" className="relative flex w-[215px] items-center justify-center border border-black ">
        <span className="absolute left-0 ml-10 mt-5 size-5 -translate-y-3">{icon}</span>
        <span className="ml-10 text-[13px] font-semibold">{title}</span>
      </Button>
    </div>
  )
}

export default CustomOutlineButton
