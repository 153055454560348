import { BarDatum, ResponsiveBar } from '@nivo/bar'
import { Link } from 'react-router-dom'

import { BarChartValueWithColor } from '@/types/graph'
import { cn } from '@/lib/utils'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'

type CardStatsBarchartProps = {
  title: string
  value: string
  data: BarChartValueWithColor[]
  height: string
  colors: string[]
  keys: string[]
  indexBy: string
  to: string
}

const fillMissingPeriods = (
  data: BarChartValueWithColor[],
  periods: number,
  defaultValue: number,
  defaultColor: string,
  indexBy: string
): BarChartValueWithColor[] => {
  const processedData = data.reduce(
    (acc, item) => {
      acc[item[indexBy]] = item
      return acc
    },
    {} as Record<string, BarChartValueWithColor>
  )

  const filledData = Array.from({ length: periods }, (_, i) => {
    const item = processedData[i]

    if (item) {
      return item
    }
    return {
      [indexBy]: i,
      local_value: defaultValue,
      average_value: defaultValue,
      color: defaultColor,
    } satisfies BarChartValueWithColor
  })

  return filledData
}

const CardStatsBarchart = ({ title, value, data, height, keys, indexBy, to }: CardStatsBarchartProps) => {
  const processedData = fillMissingPeriods(data, 4, 3, '#f1f5f9', indexBy)

  return (
    <>
      {data.length === 0 ? (
        <Card className="mr-4 md:mr-1">
          <CardHeader className="p-4">
            <CardTitle>{title}</CardTitle>
          </CardHeader>

          <CardContent className="p-4">
            <div className="space-y-2 text-center">
              <h3 className="text-xl ">No data available</h3>
              <p className="text-muted-foreground">There is currently no data to display.</p>
            </div>
          </CardContent>
        </Card>
      ) : (
        <Card className="mr-4 cursor-pointer md:mr-1">
          <Link to={to}>
            <CardHeader className="p-4">
              <CardTitle>{title}</CardTitle>
              <div className="py-4">
                <p className="text-4xl">{value}</p>
              </div>
            </CardHeader>
            <CardContent className="p-0 pb-2">
              <div className={height}>
                <ResponsiveBar
                  data={processedData as unknown as BarDatum[]}
                  keys={keys}
                  indexBy={indexBy}
                  colors={({ data }) => data.color as string}
                  enableGridX={false}
                  enableGridY={false}
                  enableTotals={false}
                  enableLabel={true}
                  label={(d) => {
                    return `P${d.index + 1}`
                  }}
                  labelTextColor={'#FFFFFF'}
                  padding={0.2}
                  tooltip={({ value, color, index }) => (
                    <div className="rounded-md bg-muted p-2 shadow-md">
                      <div className="flex items-center justify-evenly">
                        <div className={cn('mr-2 size-2 rounded-full')} style={{ backgroundColor: color }} />
                        <span>{`P${index + 1}`}</span>
                        <span className="ml-5 text-muted-foreground">{value}</span>
                      </div>
                    </div>
                  )}
                />
              </div>
            </CardContent>
          </Link>
        </Card>
      )}
    </>
  )
}

export default CardStatsBarchart
