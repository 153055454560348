import { Info } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'

interface CardAnalyticsProps {
  description?: React.ReactNode | string
  icon: React.ReactNode
  value: number | string
  title: string
  bgColor?: string
  total?: number
  colorIcon?: string
}

const CardAnalytics = ({ description, icon, title, value, bgColor, total, colorIcon }: CardAnalyticsProps) => {
  const isString = typeof value === 'string'
  return (
    <Card>
      <CardHeader>
        {description && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex items-center justify-end">
                  <Info className={cn('size-5', colorIcon)} />
                </div>
              </TooltipTrigger>
              <TooltipContent data-side="bottom" className="w-96">
                {description}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </CardHeader>
      <CardContent>
        <div className="flex items-center justify-center">
          <div className={cn('flex size-10 items-center justify-center rounded-full', bgColor)}>{icon}</div>
        </div>

        <div className="flex items-center justify-center py-4">
          <span className="text-5xl font-bold">
            {value} {isString ? '%' : null}
          </span>
        </div>
        <div className="flex items-center justify-center">
          <>
            {total && total > 0 ? (
              <div className="flex items-center justify-center">
                <div
                  className={cn(
                    'mr-1 flex size-6 items-center justify-center rounded-full text-xs text-muted-foreground',
                    bgColor
                  )}
                >
                  {total}
                </div>
              </div>
            ) : null}
            <p className="text-sm text-muted-foreground">{title}</p>
          </>
        </div>
      </CardContent>
    </Card>
  )
}

export default CardAnalytics
