import { Site } from '@/client/backend/models/site'
import L, { LatLng, LatLngExpression } from 'leaflet'

export const getSiteCenter = (site: Site): LatLng => {
  const coordinates = site.geometry?.coordinates?.[0]

  const bounds = getBounds(coordinates)
  return bounds.getCenter()
}

export const getBounds = (coordinates?: number[][]): L.LatLngBounds => {
  if (!coordinates || coordinates.length === 0) {
    return L.latLngBounds([0, 0], [0, 0])
  }

  const invertedCoordinates = L.GeoJSON.coordsToLatLngs(coordinates) as LatLng[]
  const polygon = L.polygon(invertedCoordinates)
  return polygon.getBounds()
}

export const invertCoordinates = (coordinates: number[][] | number[][][] | undefined): LatLng[] => {
  if (!coordinates || coordinates.length === 0) {
    return [{ lat: 0, lng: 0 }] as LatLng[]
  }
  return L.GeoJSON.coordsToLatLngs(coordinates) as LatLng[]
}

export const DEFAULT_RADIUS = 1500

export const getPoint = (coordinates: number[] | undefined): LatLngExpression => {
  if (!coordinates || coordinates.length === 0) {
    return { lat: 0, lng: 0 } as LatLng
  }
  const lat = coordinates[1]
  const lng = coordinates[0]

  return { lat, lng } as LatLngExpression
}

export const DEFAULT_CENTER = { lat: 45.7831, lng: 3.0824 }
