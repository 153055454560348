import { Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Progress } from '@/components/ui/progress'
import { Separator } from '@/components/ui/separator'
import { Icons } from '@/components/icons'

interface SitesListProps {
  onSiteHover: () => void
  onSiteHoverOut: () => void
  site: Site
}

const SitesCard = ({ site, onSiteHover, onSiteHoverOut }: SitesListProps) => {
  const { t } = useTranslation()

  const takenActions = site.properties?.actions_taken ?? []
  const lastTakenAction = takenActions
    .map((site) => site.date_done ?? '')
    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0]

  const numberOfTakenInitiatives = takenActions?.filter((action) => action?.status === 'DONE')
  const numberOfTakenInitiativesLength = numberOfTakenInitiatives?.length ?? 0

  const takenAndPlannedInitiatives = takenActions.length ?? 0

  const total = (numberOfTakenInitiativesLength / takenAndPlannedInitiatives) * 100

  const onMouseHover = () => {
    onSiteHover()
  }

  const handleMouseOut = () => {
    onSiteHoverOut()
  }

  const totalBudget = site.properties?.actions_taken
    ?.map((action) => Number(action?.planned_cost) || 0)
    .reduce((acc, curr) => acc + Number(curr), 0)

  return (
    <Card
      className="flex min-h-[280px] w-full max-w-[360px] flex-col  px-10 hover:cursor-pointer"
      onMouseOver={onMouseHover}
      onMouseOut={handleMouseOut}
    >
      <div className="pb-1 pt-4">
        <p className="truncate text-xl font-semibold">{site?.properties?.name}</p>
      </div>
      <Separator className="bg-biodivLimeFiveHundred" />
      <div className="mb-4 space-y-2">
        <div className="mt-4 flex flex-row items-center justify-between text-sm">
          <div className="flex w-full flex-row items-center justify-between space-x-2">
            <div className="flex flex-row">
              <p>{t(i18nKeys.beeoinitiative.sites.initiatives)}</p>
              <p className="text-xs text-gray-300">{lastTakenAction}</p>
            </div>
            <p className="text-sm font-bold">
              {numberOfTakenInitiativesLength}/{takenAndPlannedInitiatives}
            </p>
          </div>
        </div>
        <Progress className="h-2" value={total} bgColor="bg-biodivLimeFiveHundred" />
      </div>
      <div className="flex flex-col space-y-4">
        <Separator />
        <div className="flex flex-row items-center">
          <div className="flex flex-1 flex-col items-center text-sm">
            <p>{t(i18nKeys.beeoinitiative.sites.totalBudget)}</p>
            <div className="flex flex-row items-center font-semibold">
              {totalBudget} <Icons.Euro className="size-3.5" />
            </div>
          </div>
          <Separator orientation="vertical" />
          <div className="flex flex-1 flex-col items-center text-sm">
            <p>{t(i18nKeys.beeoinitiative.sites.score)}</p>
            <p className="flex flex-row gap-x-1 font-semibold">
              {site?.properties?.initiative_score} <span>{t(i18nKeys.beeoinitiative.sites.pts)}</span>
            </p>
          </div>
        </div>
        <Separator />
      </div>

      <div className="flex items-center justify-center pt-6">
        <Link to={getPath(Routes.BEEOINITIATIVE_SITE, { params: { siteId: site.id } })}>
          <Button variant="outline" size="lg" className="h-[35px] w-24 border-2 border-black">
            {t(i18nKeys.beeoinitiative.sites.details)}
          </Button>
        </Link>
      </div>
    </Card>
  )
}

export default SitesCard
