import { usersResetPasswordCreate } from '@/client/backend/api/users/users'
import { i18nKeys } from '@/locales/keys'
import { zodResolver } from '@hookform/resolvers/zod'
import { isAxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { goToLoginPage } from '@/lib/utils'
import { ForgotPasswordchema, ForgotPasswordFormValues } from '@/lib/validation/forgot-password-schema'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { toast } from '@/components/ui/use-toast'

const ForgotPasswordPage = () => {
  const { t } = useTranslation()
  const form = useForm<ForgotPasswordFormValues>({
    resolver: zodResolver(ForgotPasswordchema),
    defaultValues: {
      email: '',
    },
  })

  const onSubmit = async ({ email }: ForgotPasswordFormValues) => {
    try {
      await usersResetPasswordCreate({ email: email })

      toast({
        title: t(i18nKeys.global.auth.forgotpassword.success),
      })
      goToLoginPage()
    } catch (error) {
      if (isAxiosError(error) && error.response?.data) {
        toast({
          title: t(i18nKeys.global.auth.forgotpassword.error),
          description: error.response.data[0],
        })
      }
    }
  }
  return (
    <Form {...form}>
      <form className="space-y-6" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel> {t(i18nKeys.global.auth.forgotpassword.email)}</FormLabel>
              <FormControl>
                <Input type="email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div>
          <Button type="submit" className="w-full">
            {t(i18nKeys.global.auth.forgotpassword.submit)}
          </Button>
        </div>
      </form>
    </Form>
  )
}

export default ForgotPasswordPage
