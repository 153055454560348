import { sitesClcList, sitesRetrieve, sitesSpeciesList } from '@/client/backend/api/sites/sites'

const generalAnalysisLoader = async ({ params }) => {
  const { siteId } = params
  const [clc, gbif, site] = await Promise.all([sitesClcList(siteId), sitesSpeciesList(siteId), sitesRetrieve(siteId)])

  return { clc, site, gbif }
}

export default generalAnalysisLoader
