import { initiativesList } from '@/client/backend/api/initiatives/initiatives'
import { sitesList } from '@/client/backend/api/sites/sites'

const initiativeSiteLoader = async () => {
  const [siteList, initiatives] = await Promise.all([
    sitesList({ include: 'actions-taken,latest_action_taken_date,initiative_score', is_in_initiative: true }),
    initiativesList(),
  ])

  return {
    siteList,
    initiatives,
  }
}
export default initiativeSiteLoader
