import { useState } from 'react'
import { sitesActionsTakenCreate } from '@/client/backend/api/sites/sites'
import { Initiative, Response, Site, StatusEnum } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import SelectInitiative from 'components/beeoinitiative/SelectInitiative'
import StatusListBox from 'components/beeoinitiative/StatusListBox'
import { useTranslation } from 'react-i18next'
import { NavLink, useLoaderData, useNavigate, useParams } from 'react-router-dom'

import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Button } from '@/components/ui/button'
import { Icons } from '@/components/icons'
import Title from '@/components/title'

interface NewInitiativeData {
  site: Site
  initiatives: Initiative[]
  siteResponses: Response[]
}

const today = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  return `${year}-${month}-${day}`
}

function NewInitiativePage() {
  const { site, initiatives, siteResponses } = useLoaderData() as Awaited<NewInitiativeData>
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { initiativeId } = useParams()
  const currentInitiative = initiatives.find((init) => init.id == parseInt(initiativeId ?? '-1'))

  const [selectedStatus, setSelectedStatus] = useState<StatusEnum>(StatusEnum.PENDING)
  const [selectedInitiative, setSelectedInitiative] = useState(currentInitiative ?? ({} as Initiative))
  const [selectedDate, setSelectedDate] = useState(today())

  if (!site) return

  const handleSubmitForm = async (e) => {
    e.preventDefault()

    if (site && site.id !== undefined) {
      await sitesActionsTakenCreate(site.id.toString(), {
        date_start: selectedDate,
        initiative: selectedInitiative?.id,
        status: selectedStatus,
      })
      //await new Api().createActionTaken(site.id, selectedInitiative.id, selectedDate, selectedStatus)
      navigate(getPath(Routes.BEEOINITIATIVE_SITE, { params: { siteId: site.id } }))
    }
  }

  function includeInitiative(initiative, siteResponses) {
    let isIncluded = true
    if (initiative.outside) {
      isIncluded = siteResponses[0]?.answer === 'Oui'
    }
    if (initiative.inside && isIncluded) {
      isIncluded = siteResponses[1]?.answer === 'Oui'
    }
    return isIncluded
  }
  const filteredInits = initiatives.filter((initiative) => includeInitiative(initiative, siteResponses))

  return (
    <div className="flex h-screen flex-col">
      <div className="flex flex-row items-center space-x-32 p-4 text-sm">
        <div>
          <NavLink
            to={getPath(Routes.BEEOINITIATIVE_SITE, { params: { siteId: site.id } })}
            className="flex flex-row items-center space-x-2 text-gray-500"
          >
            <Icons.MoveLeft className="w-6" />
            <p className="text-sm">{t(i18nKeys.beeoinitiative.common.breadcrumb.back)}</p>
          </NavLink>
        </div>
        <div>
          <Title className="p-4 text-center">
            {t(i18nKeys.beeoinitiative.newInitiative.title)} {site?.properties?.name}
          </Title>
        </div>
      </div>

      <div className="flex items-center justify-center space-x-8 p-12">
        <div className="flex w-6/12">
          <div className="w-full ">
            <form
              onSubmit={handleSubmitForm}
              method="post"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                width: '100%',
              }}
            >
              <input type="hidden" name="site" value={site.id} />
              <div>
                <label className="flex w-full flex-col gap-1">
                  <span>{t(i18nKeys.beeoinitiative.newInitiative.initiative)}: </span>
                  <SelectInitiative
                    setSelectedInitiative={setSelectedInitiative}
                    selectedInitiative={selectedInitiative}
                    initiatives={filteredInits}
                  />
                </label>
              </div>
              <div>
                <label className="flex w-full flex-col gap-1">
                  <span>{t(i18nKeys.beeoinitiative.newInitiative.date)}: </span>
                  <input
                    type="date"
                    name="date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                    className="flex-1 rounded-md border-2 border-gray-300 px-3 text-sm leading-loose"
                  />
                </label>
              </div>

              <div>
                <label className="flex w-full flex-col gap-1">
                  <span>{t(i18nKeys.beeoinitiative.newInitiative.status)}: </span>
                  <div className="relative mt-1">
                    <StatusListBox selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} />
                  </div>
                </label>
              </div>
              <div className="flex h-16 items-end justify-center">
                <Button className={'w-36'}>{t(i18nKeys.beeoinitiative.newInitiative.save)}</Button>
              </div>
            </form>
          </div>
        </div>
        <div className="w-4/12">
          <img src={selectedInitiative.image1} alt="initiative illustration" className="w-96 rounded-md" />
        </div>
      </div>
    </div>
  )
}

export default NewInitiativePage
