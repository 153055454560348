import { SensitiveAreaOut, SiteGeometry } from '@/client/backend/models'
import * as turf from '@turf/turf'
import { LatLng, PathOptions } from 'leaflet'
import {
  Circle,
  LayerGroup,
  LayersControl,
  MapContainer,
  Polygon,
  ScaleControl,
  TileLayer,
  Tooltip,
} from 'react-leaflet'

import { DEFAULT_RADIUS, invertCoordinates } from '@/lib/geo'

const ZOOM_LEVEL = 13

const PROTECTED_ZONES = {
  A: {
    code: 'A',
    color: 'green',
    name: 'Special protection area - Birds',
  },
  B: {
    code: 'B',
    color: 'blue',
    name: 'Special area of conservation - Habitats',
  },
  C: {
    code: 'C',
    color: 'red',
    name: 'Combined area(Birds & Habitats)',
  },
}
interface SiteProtectedZonesMapProps {
  protectedAreas: SensitiveAreaOut[]
  designatedAreas: SensitiveAreaOut[]
  center: LatLng
  area?: SiteGeometry
}

const SiteProtectedZonesMap = ({ protectedAreas, center, designatedAreas }: SiteProtectedZonesMapProps) => {
  function renderAreas() {
    const getNewGeometry = (protectedArea: SensitiveAreaOut): LatLng[] | null => {
      const coordinates = protectedArea.intersection?.['coordinates']
      const polygonType = protectedArea.intersection?.['type'] as string

      if (polygonType === 'Polygon') {
        return invertCoordinates(coordinates?.[0])
      } else if (polygonType === 'MultiPolygon') {
        return protectedArea.intersection?.['coordinates'].flat().map((polygon) => invertCoordinates(polygon))
      }
      return null
    }

    const createPolygonOptions = (): PathOptions => ({
      color: 'white',
      fillColor: 'orange',
      fillOpacity: 0.5,
      weight: 1,
      opacity: 0.5,
    })

    const areas =
      protectedAreas === null
        ? []
        : protectedAreas.map((protectedArea, idx) => {
            const options = createPolygonOptions()
            const newGeom = getNewGeometry(protectedArea)

            if (!newGeom) {
              return null
            }

            return (
              <Polygon key={idx} pathOptions={options} positions={newGeom} pane="markerPane" className="z-0">
                <Tooltip sticky>
                  <ul>
                    <li>{protectedArea.name}</li>
                    <li>{protectedArea.type && PROTECTED_ZONES[protectedArea.type].name}</li>
                    <li>{(turf.area(protectedArea.intersection) / 10000).toFixed(2)} ha</li>
                  </ul>
                </Tooltip>
              </Polygon>
            )
          })

    return areas
  }

  function renderCddas() {
    const getNewGeometry = (designatedAreas: SensitiveAreaOut): LatLng[] | null => {
      const coordinates = designatedAreas.intersection?.['coordinates']
      const polygonType = designatedAreas.intersection?.['type'] as string

      if (polygonType === 'Polygon') {
        return invertCoordinates(coordinates?.[0])
      } else if (polygonType === 'MultiPolygon') {
        return designatedAreas.intersection?.['coordinates'].flat().map((polygon) => invertCoordinates(polygon))
      }
      return null
    }

    const createPolygonOptions = (): PathOptions => ({
      color: 'white',
      fillColor: 'darkblue',
      fillOpacity: 0.5,
      weight: 1,
      opacity: 0.5,
    })
    const nationallyDesignatedAreas =
      designatedAreas === null
        ? []
        : designatedAreas.map((designatedArea, idx) => {
            const options = createPolygonOptions()
            const newGeom = getNewGeometry(designatedArea)

            if (!newGeom) {
              return null
            }

            return (
              <Polygon key={idx} pathOptions={options} positions={newGeom} pane="markerPane" className="z-0">
                <Tooltip sticky>
                  <ul>
                    <li>{designatedArea.name}</li>
                  </ul>
                </Tooltip>
              </Polygon>
            )
          })

    return nationallyDesignatedAreas
  }

  return (
    <MapContainer
      className="z-0 min-h-[30rem] w-[25rem] rounded-l-md"
      center={center}
      zoom={ZOOM_LEVEL}
      scrollWheelZoom={false}
      attributionControl={false}
    >
      <Circle center={center} color={'cadetblue'} fillColor="cadetblue" radius={DEFAULT_RADIUS} />
      <TileLayer key="tile" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <LayersControl position="topright">
        <LayersControl.Overlay checked name="CDDA">
          <LayerGroup>{renderCddas()}</LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name="Natura 2000">
          <LayerGroup>{renderAreas()}</LayerGroup>
        </LayersControl.Overlay>
      </LayersControl>
      <ScaleControl position="bottomleft" />
    </MapContainer>
  )
}

export default SiteProtectedZonesMap
