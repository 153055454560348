import { useEffect, useState } from 'react'
import { CultureMapping } from '@/client/backend/models'
import { ColumnDef } from '@tanstack/react-table'

import { Badge } from '@/components/ui/badge'
import { Checkbox } from '@/components/ui/checkbox'
import CultureMappingsTableRowAction from '@/components/culture-mappings-table-row-action'
import { DataTable } from '@/components/data-table/data-table'
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header'

type CultureMappingsTableProps = {
  data: CultureMapping[]
}

const CultureMappingsTable = ({ data }: CultureMappingsTableProps) => {
  const [mappings, setMappings] = useState<CultureMapping[]>(data)

  useEffect(() => {
    setMappings(data)
  }, [data])

  const columns: ColumnDef<CultureMapping>[] = [
    {
      id: 'select',
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
          className="translate-y-[2px]"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
          className="translate-y-[2px]"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'id',
      header: ({ column }) => <DataTableColumnHeader column={column} children="" />,
      cell: ({ row }) => <div className="w-[80px]">{row.getValue('id')}</div>,
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'phyto_culture.name',
      id: 'name',
      header: ({ column }) => <DataTableColumnHeader column={column} children="Phyto Name" />,
      cell: ({ row }) => {
        return (
          <div className="flex space-x-2">
            <span className="max-w-[500px] truncate font-medium">{row.original.phyto_culture.name}</span>
          </div>
        )
      },
    },
    {
      accessorKey: 'pac_culture.source_culture_name',
      id: 'source_culture_name',
      header: ({ column }) => <DataTableColumnHeader column={column} children="Pac Name" />,
      cell: ({ row }) => {
        return (
          <div className="flex space-x-2">
            <span className="max-w-[500px] truncate font-medium">{row.original.pac_culture.source_culture_name}</span>
          </div>
        )
      },
    },

    {
      accessorKey: 'validated',
      id: 'validated',
      header: ({ column }) => <DataTableColumnHeader column={column} children="Validate" />,
      cell: ({ row }) => {
        return (
          <div className="flex space-x-2">
            <span className="max-w-[500px] truncate font-medium ">
              <Badge className={row.original.validated ? 'bg-green-500' : 'bg-red-500'}>
                {row.original.validated ? 'Validated' : 'Not validated'}
              </Badge>
            </span>
          </div>
        )
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        return <CultureMappingsTableRowAction mapping={row.original} mappings={mappings} setMappings={setMappings} />
      },
    },
  ]

  return <DataTable columns={columns} data={mappings} filterIdColumn="source_culture_name" />
}

export default CultureMappingsTable
