import { auditsList } from '@/client/backend/api/audits/audits'
import { initiativesList } from '@/client/backend/api/initiatives/initiatives'
import { questionsList } from '@/client/backend/api/questions/questions'
import { sitesActionsTakenList, sitesResponsesList, sitesRetrieve } from '@/client/backend/api/sites/sites'

export async function takenInitiativeLoader({ params }) {
  const { siteId } = params

  if (!siteId) throw new Response('', { status: 404 })

  const [site, actionsTaken, questions, responses, initiatives, audits] = await Promise.all([
    sitesRetrieve(siteId, { include: 'initiative_score' }),
    sitesActionsTakenList(siteId, { include: 'initiative' }),
    questionsList(),
    sitesResponsesList(siteId),
    initiativesList(),
    auditsList(),
  ])

  return {
    site,
    actionsTaken,
    questions,
    responses,
    initiatives,
    audits,
  }
}
