import { useEffect, useRef } from 'react'
import { Site } from '@/client/backend/models'
import L, { Map, MarkerOptions } from 'leaflet'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'

import { DEFAULT_CENTER, getSiteCenter } from '@/lib/geo'
import { getMapIcon, hoverSiteIcon } from '@/lib/map'

interface SitesMapProps {
  sites: Site[]
  hoveredCard: number | null
  hoveredCardRef: React.MutableRefObject<number | null>
}
interface CustomMarkerOptions extends MarkerOptions {
  id?: number
}

const SitesMap = ({ sites, hoveredCard, hoveredCardRef }: SitesMapProps) => {
  const mapRef = useRef<Map>(null)
  const markerRefs = useRef({})

  const worldBounds = new L.LatLngBounds(new L.LatLng(-88, -180), new L.LatLng(88, 180))

  const openPopup = (id) => {
    if (markerRefs.current[id]) {
      markerRefs.current[id].openPopup()
    }
  }
  useEffect(() => {
    if (hoveredCard) {
      openPopup(hoveredCard)
    }
  }, [hoveredCard])

  useEffect(() => {
    hoveredCardRef.current = hoveredCard
  }, [hoveredCard])

  const createClusterIcon = (cluster) => {
    const count = cluster.getChildCount()
    const clusterMarkers = cluster.getAllChildMarkers()

    const clusterIds = clusterMarkers.map((marker) => (marker.options as CustomMarkerOptions).id)

    const isHovered = clusterIds.includes(hoveredCardRef.current)

    return L.divIcon({
      html: `<div class="custom-cluster flex items-center justify-center w-9 h-9 text-sm ${
        isHovered ? 'bg-teal-100 border-2' : 'bg-white'
      } border border-biodivTealSevenHundred text-biodivTealSevenHundred rounded-full">
          ${count}
        </div>`,
      className: 'custom-cluster-icon',
      iconSize: L.point(40, 40, true),
    })
  }

  return (
    <MapContainer
      className="z-0 size-full"
      center={DEFAULT_CENTER}
      zoom={6}
      dragging={true}
      scrollWheelZoom={false}
      ref={mapRef}
      maxBounds={worldBounds}
      maxBoundsViscosity={1}
    >
      <TileLayer key="tile" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <MarkerClusterGroup
        key={Date.now()}
        spiderfyOnMaxZoom={true}
        zoomToBoundsOnClick={true}
        showCoverageOnHover={false}
        maxClusterRadius={50}
        iconCreateFunction={createClusterIcon}
      >
        {sites?.map((site) => {
          const isHovered = hoveredCard === site.id
          return (
            <Marker
              icon={isHovered ? hoverSiteIcon('pin') : getMapIcon('newDot')}
              key={site.id}
              position={getSiteCenter(site)}
              ref={(ref) => {
                if (ref) {
                  markerRefs.current[site.id] = ref
                  return ((ref.options as CustomMarkerOptions).id = site.id)
                }
              }}
            >
              <Popup></Popup>
            </Marker>
          )
        })}
      </MarkerClusterGroup>
    </MapContainer>
  )
}

export default SitesMap
