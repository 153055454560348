import { Audit } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import ComingSoon from '@/pages/beeoinitiative/dashboard/coming-soon-card'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Card } from '@/components/ui/card'
import { Icons } from '@/components/icons'

interface RapportsProps {
  audits: Audit[]
}

const Reports = ({ audits }: RapportsProps) => {
  const { t } = useTranslation()
  const numberOfAudits = audits.length
  return (
    <Card className="relative flex max-h-[330px] flex-col  space-y-8 px-10 py-9">
      <div className="flex flex-row justify-between">
        <h1 className="text-xl font-semibold">{t(i18nKeys.beeoinitiative.dashboard.rapports.title)}</h1>
        <Icons.ClipboardCheck className="size-8 text-biodivBlueSixHundred" />
      </div>

      <div className="flex flex-col pr-1">
        <div className="flex flex-row items-center justify-between">
          {' '}
          <p className="pb-3 text-sm font-semibold">{t(i18nKeys.beeoinitiative.dashboard.rapports.firstSubtitle)}</p>
          <p className="text-3xl font-semibold">{numberOfAudits}</p>
        </div>
        <Link to={''} className="text-sm underline">
          {t(i18nKeys.beeoinitiative.dashboard.rapports.link)}
        </Link>
      </div>

      <div className="relative -mx-10 -mb-4  mt-4 flex flex-col rounded-b-lg border border-biodivVioletEightHundred py-10">
        <ComingSoon big={true} />
        <div className="px-10 opacity-20 blur-[1px]">
          <div className="flex flex-row items-center justify-between">
            {' '}
            <p className="pb-3 text-sm font-semibold">{t(i18nKeys.beeoinitiative.dashboard.rapports.secondSubtitle)}</p>
            <p className="text-3xl font-semibold">6</p>
          </div>
          <Link to={''} className="text-sm underline">
            {t(i18nKeys.beeoinitiative.dashboard.rapports.link)}
          </Link>
        </div>
      </div>
    </Card>
  )
}
export default Reports
