import { ListValues } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { t } from 'i18next'

import { toTitleCase } from '@/lib/string'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'

type CardLongListProps = {
  item: ListValues
}

const CardLongList = ({ item }: CardLongListProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{toTitleCase(item.name)}</CardTitle>
        <CardDescription>{t(i18nKeys.beeomonitoring.biodiversity.species.type[item.name])}</CardDescription>
      </CardHeader>
      <CardContent>
        <ul className="space-y-2">
          {item.values.sort().map((value, index) => {
            return <li key={index}>{value}</li>
          })}
        </ul>
      </CardContent>
    </Card>
  )
}

export default CardLongList
