import { Badge } from '@/components/ui/badge'

const MONTHS = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']

interface MonthlyScheduleProps {
  monthsToColor: number[]
}

const MonthlySchedule = ({ monthsToColor }: MonthlyScheduleProps) => {
  const uniqueMonthsToColor = [...new Set(monthsToColor)]

  return (
    <div className="flex flex-row space-x-1">
      {MONTHS.map((month, index) => (
        <Badge
          key={month}
          variant="outline"
          className={`flex size-5 items-center justify-center rounded-sm text-gray-400 ${
            uniqueMonthsToColor.includes(index + 1) ? 'bg-biodivTealSevenHundred text-white' : ''
          }`}
        >
          {month}
        </Badge>
      ))}
    </div>
  )
}

export default MonthlySchedule
