import React from 'react'

const titleStyles = {
  h1: 'text-3xl',
  h2: 'text-2xl',
  h3: 'text-xl',
  h4: 'text-lg',
  h5: 'text-sm',
  h6: 'text-xs',
}

const titleColors = {
  black: 'text-black',
  blue: '',
}

interface TitleType {
  level?: string
  color?: string
  className?: string
  children: React.ReactNode
}

const Title = ({ level = 'h1', color = 'black', className, children }: TitleType) => {
  const levelClass = titleStyles[level]
  const colorClass = color ? titleColors[color] : ''
  const customClassName = `${levelClass} ${colorClass} ${className || ''}`

  return React.createElement(level, { className: customClassName }, children)
}

export default Title
