/**
 * * `PENDING` - Pending
 * `IN_PROGRESS` - In progress
 * `DONE` - Done
 * `CANCELLED` - Cancelled
 */
export type StatusEnum = (typeof StatusEnum)[keyof typeof StatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StatusEnum = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  CANCELLED: 'CANCELLED',
} as const
