import { BarChartByYear, ListByYear, ListValues, PieChartByYear } from '@/client/backend/models'

import { BarChartValueWithColor, ValuePieChart } from '@/types/graph'
import { COLORS } from '@/lib/constants/constants'

export const getPiePeriodData = (data: PieChartByYear[], periodKey: string, selectedYear: string): ValuePieChart[] => {
  const currentYearData = data.find((item) => item.year === selectedYear)
  const period = currentYearData?.periods.find((item) => item.key === periodKey)
  return (
    period?.items.map((item, index) => ({
      id: index,
      label: item.name,
      value: item.value,
      color: COLORS[index % COLORS.length],
    })) || []
  )
}

export const getBarPeriodData = (
  data: BarChartByYear[],
  periodKey: string,
  selectedYear: string
): BarChartValueWithColor[] => {
  const currentYearData = data.find((item) => item.year === selectedYear)
  const period = currentYearData?.periods.find((item) => item.key === periodKey)
  return (
    (period?.items.map((item, index) => ({
      ...item,
      color: COLORS[index % COLORS.length],
    })) as BarChartValueWithColor[]) || []
  )
}

export const getListPeriodData = (data: ListByYear[], periodKey: string, selectedYear: string): ListValues[] => {
  const currentYearData = data.find((item) => item.year === selectedYear)
  const period = currentYearData?.periods.find((item) => item.key === periodKey)
  return period?.items || []
}
