import { Account, AccountStats, PaginatedSiteList } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { ResponsivePie } from '@nivo/pie'
import { BadgeCheck, BadgeMinus, MapPin, Squirrel } from 'lucide-react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { cn } from '@/lib/utils'
import { buttonVariants } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import CardAnalytics from '@/components/card-analytics'
import Loader from '@/components/loader'
import NoSitesMessage from '@/components/no-sites-message'
import CenteredLayer from '@/components/pie/centered-layer'
import SitesTable from '@/components/sites-table'
import { H3, H4 } from '@/components/typography'

interface DashboardBiodiversityProps {
  sites: PaginatedSiteList
  stats: AccountStats
  account: Account
}
const DashboardBiodiversity = ({ sites, account, stats }: DashboardBiodiversityProps) => {
  const { t } = useTranslation()
  if (!sites.results?.features || !account) return <Loader />

  const notAdjacent = stats.nb_sites_with_out_sensitive_area

  const dataPieChart = [
    {
      id: 'in',
      label: t(i18nKeys.beeoimpact.dashboard.metrics.pie.in),
      value: stats.nb_sites_with_in_sensitive_area,
    },
    {
      id: 'not',
      label: t(i18nKeys.beeoimpact.dashboard.metrics.pie.notAdjacent),
      value: notAdjacent,
    },

    {
      id: 'adjacent',
      label: t(i18nKeys.beeoimpact.dashboard.metrics.pie.adjacent),
      value: stats.nb_sites_with_adjacent_sensitive_area,
    },
  ]

  const notAdjacentPercentage = (notAdjacent / stats.nb_sites) * 100 || 0
  const adjacentPercentage = (stats.nb_sites_with_adjacent_sensitive_area / stats.nb_sites) * 100 || 0
  const inPercentage = (stats.nb_sites_with_in_sensitive_area / stats.nb_sites) * 100 || 0

  const totalIUCNRedListCR = stats.nb_species_in.CR + stats.nb_species_adjacent.CR
  const totalIUCNRedListEN = stats.nb_species_in.EN + stats.nb_species_adjacent.EN
  const totalIUCNRedListVU = stats.nb_species_in.VU + stats.nb_species_adjacent.VU
  const totalIUCNRedListNT = stats.nb_species_in.NT + stats.nb_species_adjacent.NT
  const totalIUCNRedListLC = stats.nb_species_in.LC + stats.nb_species_adjacent.LC

  const totalIUCNRedList = totalIUCNRedListCR + totalIUCNRedListEN + totalIUCNRedListVU

  const percentageAdjacentIn = (adjacentPercentage + inPercentage).toFixed(0)
  const totalAdjacentIn = stats.nb_sites_with_adjacent_sensitive_area + stats.nb_sites_with_in_sensitive_area
  return (
    <>
      {stats.nb_sites === 0 ? (
        <NoSitesMessage />
      ) : (
        <section>
          <div className="mb-8">
            <div className="mb-4 flex flex-row">
              <Card className="mr-4 basis-2/3 border-none bg-gray-300/20">
                <CardContent>
                  <div className="flex items-start">
                    <div className="mr-8 h-44 w-1/5">
                      <ResponsivePie
                        data={dataPieChart}
                        margin={{ top: 10, right: 20, bottom: 10, left: 10 }}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        activeOuterRadiusOffset={8}
                        enableArcLinkLabels={false}
                        enableArcLabels={false}
                        borderColor={{
                          from: 'color',
                          modifiers: [['darker', 0.2]],
                        }}
                        colors={['#f87171', '#86efac', '#fdba74']}
                        layers={[
                          'arcs',
                          'arcLabels',
                          'arcLinkLabels',
                          'legends',
                          CenteredLayer(`${stats.nb_sites_with_in_sensitive_area}`),
                        ]}
                      />
                    </div>
                    <div className="w-3/5">
                      <H4 className="mb-6 mt-4">{t(i18nKeys.beeoimpact.dashboard.metrics.card.in.title)}</H4>
                      <p className="text-muted-foreground">
                        {
                          <Trans
                            i18nKey={i18nKeys.beeoimpact.dashboard.metrics.in}
                            values={{
                              adjacent: stats.nb_sites_with_in_sensitive_area,
                              adjacentPercentage: inPercentage.toFixed(2),
                            }}
                            components={{ bold: <span className="font-bold" /> }}
                          />
                        }
                      </p>
                      <Link
                        to={getPath(Routes.BEEOIMPACT_SITES)}
                        className={cn(buttonVariants({ variant: 'default', size: 'lg' }), 'mt-6')}
                      >
                        {t(i18nKeys.beeoimpact.dashboard.title.viewSites)}
                      </Link>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <div className="basis-1/3">
                <CardAnalytics
                  title={t(i18nKeys.beeoimpact.dashboard.metrics.card.adjancentAndIn.title)}
                  description={t(i18nKeys.beeoimpact.dashboard.metrics.card.adjancentAndIn.description)}
                  value={percentageAdjacentIn}
                  total={totalAdjacentIn}
                  icon={<BadgeMinus className="mt-px size-5 text-purple-600" />}
                  bgColor="bg-purple-200"
                />
              </div>
            </div>

            <div>
              <H3>{t(i18nKeys.beeoimpact.dashboard.metrics.title)}</H3>
              <p className="pb-4 text-sm text-muted-foreground">
                {t(i18nKeys.beeoimpact.dashboard.metrics.description, { count: parseInt(account.nb_sites) })}
              </p>
            </div>

            <Separator />
          </div>
          <div className="mb-4 grid gap-4 md:grid-cols-2 lg:grid-cols-4">
            <CardAnalytics
              description={
                <Trans
                  i18nKey={i18nKeys.beeoimpact.dashboard.metrics.notAdjacent}
                  values={{
                    notAdjacent: notAdjacent,
                    notAdjacentPercentage: notAdjacentPercentage.toFixed(2),
                  }}
                  components={{ bold: <span className="font-bold" /> }}
                />
              }
              title={t(i18nKeys.beeoimpact.dashboard.pesticides.sites.title)}
              value={account.nb_sites}
              icon={<MapPin className="mt-px size-5 text-gray-600" />}
              bgColor="bg-gray-200"
              colorIcon="invisible"
            />
            <CardAnalytics
              title={t(i18nKeys.beeoimpact.dashboard.metrics.card.notAdjacent.title)}
              description={
                <Trans
                  i18nKey={i18nKeys.beeoimpact.dashboard.metrics.notAdjacent}
                  values={{
                    notAdjacent: notAdjacent,
                    notAdjacentPercentage: notAdjacentPercentage.toFixed(2),
                  }}
                  components={{ bold: <span className="font-bold" /> }}
                />
              }
              value={notAdjacentPercentage.toFixed(0)}
              total={notAdjacent}
              icon={<BadgeCheck className="mt-px size-5 text-green-600" />}
              bgColor="bg-green-200"
            />
            <CardAnalytics
              title={t(i18nKeys.beeoimpact.dashboard.metrics.card.adjacent.title)}
              description={
                <Trans
                  i18nKey={i18nKeys.beeoimpact.dashboard.metrics.adjacent}
                  values={{
                    adjacent: stats.nb_sites_with_adjacent_sensitive_area,
                    adjacentPercentage: adjacentPercentage.toFixed(2),
                  }}
                  components={{ bold: <span className="font-bold" /> }}
                />
              }
              value={adjacentPercentage.toFixed(0)}
              total={stats.nb_sites_with_adjacent_sensitive_area}
              icon={<BadgeMinus className="mt-px size-5 text-orange-600" />}
              bgColor="bg-orange-200"
            />
            <CardAnalytics
              title={t(i18nKeys.beeoimpact.dashboard.metrics.card.in.title)}
              description={
                <Trans
                  i18nKey={i18nKeys.beeoimpact.dashboard.metrics.in}
                  values={{
                    adjacent: stats.nb_sites_with_in_sensitive_area,
                    adjacentPercentage: inPercentage.toFixed(2),
                  }}
                  components={{ bold: <span className="font-bold" /> }}
                />
              }
              value={inPercentage.toFixed(0)}
              total={stats.nb_sites_with_in_sensitive_area}
              icon={<BadgeMinus className="mt-px size-5 text-red-600" />}
              bgColor="bg-red-200"
            />
          </div>

          <div className="my-8">
            <div className="mb-8">
              <H3>{t(i18nKeys.beeoimpact.dashboard.metrics.titleThreat)}</H3>
              <p className="pb-4 text-sm text-muted-foreground">
                <Trans
                  i18nKey={i18nKeys.beeoimpact.dashboard.metrics.totalIUCNRedList}
                  values={{
                    totalIUCNRedList: totalIUCNRedList,
                  }}
                  components={{ bold: <span className="font-bold" /> }}
                />
              </p>
              <Separator />
            </div>
            <div className="mb-4 grid gap-4 md:grid-cols-2 lg:grid-cols-3">
              <CardAnalytics
                title={t(i18nKeys.beeoimpact.dashboard.metrics.card.species.title)}
                description={t(i18nKeys.beeoimpact.dashboard.metrics.card.species.description)}
                value={totalIUCNRedList}
                icon={<Squirrel className="mt-px size-5 text-gray-600" />}
                bgColor="bg-gray-200"
              />
              <CardAnalytics
                title={t(i18nKeys.beeoimpact.dashboard.metrics.card.species.CR.title)}
                description={t(i18nKeys.beeoimpact.dashboard.metrics.card.species.CR.description)}
                value={totalIUCNRedListCR}
                icon={<Squirrel className="mt-px size-5 text-red-600" />}
                bgColor="bg-red-200"
              />
              <CardAnalytics
                title={t(i18nKeys.beeoimpact.dashboard.metrics.card.species.EN.title)}
                description={t(i18nKeys.beeoimpact.dashboard.metrics.card.species.EN.description)}
                value={totalIUCNRedListEN}
                icon={<Squirrel className="mt-px size-5 text-orange-600" />}
                bgColor="bg-orange-200"
              />
            </div>
            <div className="flex flex-col items-center justify-center  lg:flex-row lg:space-x-8 lg:space-y-0">
              <Card className="my-8 flex w-full flex-col">
                <CardContent>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead></TableHead>
                        <TableHead>{t(i18nKeys.beeoimpact.dashboard.groups.CR)}</TableHead>
                        <TableHead>{t(i18nKeys.beeoimpact.dashboard.groups.EN)}</TableHead>
                        <TableHead>{t(i18nKeys.beeoimpact.dashboard.groups.VU)}</TableHead>
                        <TableHead>{t(i18nKeys.beeoimpact.dashboard.groups.NT)}</TableHead>
                        <TableHead>{t(i18nKeys.beeoimpact.dashboard.groups.LC)}</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      <TableRow>
                        <TableCell className="font-medium">
                          {t(i18nKeys.beeoimpact.dashboard.metrics.pie.adjacent)}
                        </TableCell>
                        <TableCell className="font-medium">{stats.nb_species_adjacent.CR}</TableCell>
                        <TableCell>{stats.nb_species_adjacent.EN}</TableCell>
                        <TableCell>{stats.nb_species_adjacent.VU}</TableCell>
                        <TableCell>{stats.nb_species_adjacent.NT}</TableCell>
                        <TableCell>{stats.nb_species_adjacent.LC}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="font-medium">{t(i18nKeys.beeoimpact.dashboard.metrics.pie.in)}</TableCell>
                        <TableCell className="font-medium">{stats.nb_species_in.CR}</TableCell>
                        <TableCell>{stats.nb_species_in.EN}</TableCell>
                        <TableCell>{stats.nb_species_in.VU}</TableCell>
                        <TableCell>{stats.nb_species_in.NT}</TableCell>
                        <TableCell>{stats.nb_species_in.LC}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="font-medium">Total</TableCell>
                        <TableCell className="text-lg font-bold">{totalIUCNRedListCR}</TableCell>
                        <TableCell className="text-lg font-bold">{totalIUCNRedListEN}</TableCell>
                        <TableCell className="text-lg font-bold">{totalIUCNRedListVU}</TableCell>
                        <TableCell className="text-lg font-bold">{totalIUCNRedListNT}</TableCell>
                        <TableCell className="text-lg font-bold">{totalIUCNRedListLC}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </div>
          </div>

          <div className="my-8">
            <div className="my-8">
              <H3>{t(i18nKeys.beeoimpact.dashboard.metrics.sites)}</H3>
              <p className="pb-4 text-sm text-muted-foreground">
                {t(i18nKeys.beeoimpact.dashboard.metrics.description, { count: parseInt(account.nb_sites) })}
              </p>
              <Separator />
            </div>
            <Card className="mt-8 w-full min-w-0">
              <CardContent className="my-8">
                <SitesTable data={sites} />
              </CardContent>
            </Card>
          </div>
        </section>
      )}
    </>
  )
}

export default DashboardBiodiversity
