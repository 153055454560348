import { i18nKeys } from '@/locales/keys'
import siteSummaryLoader from '@/pages/beeoimpact/site-summary/site-summary-page.loader'
import { captureMessage } from '@sentry/browser'
import { HomeIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useLoaderData } from 'react-router-dom'

import { FeatureType } from '@/types/feature-type'
import { Zone, ZoneWithCodesItem, ZoneWithPercentage } from '@/types/zone'
import { getSiteCenter } from '@/lib/geo'
import { hasAccesTo } from '@/lib/permission'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { useAuth } from '@/hooks/useAuth'
import GeneralSummary from '@/components/beeoimpact/general-summary/general-summary'
import GeneralAnalysisSummary from '@/components/beeoimpact/impacts/general-analysis-summary'
import ImpactsSummary from '@/components/beeoimpact/impacts/impacts-summary'
import RisksSummary from '@/components/beeoimpact/pesticides/risks-summary'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@/components/breadcrumb'
import { Icons } from '@/components/icons'
import Loader from '@/components/loader'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'

const SiteSummaryPage = () => {
  const { t } = useTranslation()
  const { site, n2k, cdda, landplots, clc, gbif } = useLoaderData() as Awaited<ReturnType<typeof siteSummaryLoader>>

  const { user } = useAuth()
  if (!user) return <Loader />

  const center = getSiteCenter(site)

  const activeMatters = site.properties?.stats_pesticides?.probable_pesticides.slice(0, 5) ?? []

  const zoneGroups = {
    1: t(i18nKeys.beeoimpact.site.clcTypes.urban),
    2: t(i18nKeys.beeoimpact.site.clcTypes.agricultural),
    3: t(i18nKeys.beeoimpact.site.clcTypes.vegetation),
    4: t(i18nKeys.beeoimpact.site.clcTypes.wetAreas),
    5: t(i18nKeys.beeoimpact.site.clcTypes.waterBodies),
  }
  const iconMapping = {
    1: <Icons.Building2 />,
    2: <Icons.Sprout />,
    3: <Icons.Leaf className="size-5" />,
    4: <Icons.Waves />,
    5: <Icons.Droplet />,
  }

  const zoneWithCodes: Zone = clc.reduce((acc, data) => {
    if (!data.type.type_code_level1) {
      captureMessage(`Error in CLC data for site ${site.id}`)
      return acc
    }

    const category = data.type.type_code_level1?.toString().charAt(0)

    const obj = {
      zone: zoneGroups[category],
      area: (data.intersection_area_sq_m ?? 0) * 10000 || 0,
      category: category,
      codes: [data.type.type_code_level1?.toString()],
    } as ZoneWithCodesItem

    if (!acc[category]) {
      acc[category] = [obj]
      acc[category].total_surface = obj.area
    } else {
      acc[category].push(obj)
      acc[category].total_surface += obj.area
    }
    acc[category].key = category
    return acc
  }, {} as Zone)

  const total_surface: number = Object.values(zoneWithCodes).reduce((acc, curr) => acc + curr.total_surface, 0)

  const threatenedSpeciesNumber = gbif.filter(
    (species) => species.iucn_status_eu === 'VU' || species.iucn_status_eu === 'EN' || species.iucn_status_eu === 'CR'
  ).length

  const zoneWithPercentage = Object.entries(zoneWithCodes)
    .map(([key, value]) => {
      const icons = iconMapping[parseInt(key)]
      const description = zoneGroups[parseInt(value.key)]
      const percentage = (value.total_surface / total_surface) * 100
      return {
        icons,
        percentage,
        zone: value[0].zone,
        description: description,
      } as ZoneWithPercentage
    })
    .sort((a, b) => b.percentage - a.percentage)

  return (
    <div className="container">
      <div className="flex flex-row justify-between">
        <Breadcrumb className="mt-4">
          <BreadcrumbItem>
            <BreadcrumbLink href="/">
              <HomeIcon className="size-4" />
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href={getPath(Routes.BEEOIMPACT_SITES)}>
              {t(i18nKeys.beeoimpact.common.breadcrumb.mySites)}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{site.properties?.name}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </div>

      <PageHeader>
        <PageHeaderHeading>{site.properties?.name}</PageHeaderHeading>
      </PageHeader>
      <GeneralSummary
        site={site}
        center={center}
        zoneWithPercentage={zoneWithPercentage}
        threatenedSpeciesNumber={threatenedSpeciesNumber}
      />
      {hasAccesTo(user.features, FeatureType.POTENTIAL) && (
        <GeneralAnalysisSummary
          clc={clc}
          center={center}
          zoneWithPercentage={zoneWithPercentage}
          site={site}
          gbif={gbif}
          threatenedSpeciesNumber={threatenedSpeciesNumber}
        />
      )}
      {hasAccesTo(user.features, FeatureType.BIODIVERSITY) && (
        <ImpactsSummary center={center} protectedAreas={n2k} designatedAreas={cdda} site={site} />
      )}
      {hasAccesTo(user.features, FeatureType.PESTICIDES) && (
        <RisksSummary
          area={site.geometry}
          selectedPoint={center}
          siteId={site.id}
          landplots={landplots}
          activeMatters={activeMatters}
          site={site}
        />
      )}
    </div>
  )
}

export default SiteSummaryPage
