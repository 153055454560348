import React, { useEffect, useState } from 'react'
import { sitesCreate, sitesUpdate } from '@/client/backend/api/sites/sites'
import { BuildingTypeEnum, Site, SiteCreate } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { zodResolver } from '@hookform/resolvers/zod'
import { isAxiosError } from 'axios'
import { t } from 'i18next'
import { Loader, Loader2 } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { Circle, MapContainer, TileLayer, useMap } from 'react-leaflet'

import { DEFAULT_CENTER } from '@/lib/geo'
import { SiteFormValues, siteSchema } from '@/lib/validation/add-site-schema'
import { useAuth } from '@/hooks/useAuth'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { SheetFooter } from '@/components/ui/sheet'
import { toast } from '@/components/ui/use-toast'

type SiteFormProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setSites: React.Dispatch<React.SetStateAction<Site[]>>
  sites: Site[]
  site?: Site
  accountId?: number
}

const SiteForm = ({ sites, site, setOpen, setSites, accountId }: SiteFormProps) => {
  const { user } = useAuth()
  const center = (site?.properties?.center?.coordinates as number[]) || [0, 0]

  const form = useForm<SiteFormValues>({
    resolver: zodResolver(siteSchema),
    defaultValues: {
      name: site?.properties?.name || '',
      lat: center[1] || 0,
      lng: center[0] || 0,
      surface: site?.properties?.surface_ha || 0,
      radius: site?.properties?.biodiversity_radius || 1500,
      type: site?.properties?.building_type || BuildingTypeEnum.INDUSTRIAL,
    },
    shouldUnregister: false,
    mode: 'onSubmit',
  })

  const [coordinates, setCoordinates] = useState(DEFAULT_CENTER)
  const latitude = form.watch('lat')
  const longitude = form.watch('lng')

  const [formPending, setFormPending] = useState(false)

  useEffect(() => {
    if (latitude && longitude && !isNaN(latitude) && !isNaN(longitude)) {
      setCoordinates({ lat: latitude, lng: longitude })
    }
  }, [latitude, longitude])

  if (!user) return <Loader />

  const radiusMapping: Record<BuildingTypeEnum, number> = {
    [BuildingTypeEnum.INDUSTRIAL]: 1500,
    [BuildingTypeEnum.OFFICE]: 100,
    [BuildingTypeEnum.AGRICULTURAL]: 100,
    [BuildingTypeEnum.EXTRACTION]: 10000,
    [BuildingTypeEnum.MANUFACTURING]: 5000,
  }

  const onChangeType = (selectedType: BuildingTypeEnum) => {
    const updatedRadius = radiusMapping[selectedType]
    form.setValue('radius', updatedRadius)
  }

  const onSubmit = async (data: SiteFormValues) => {
    const dataObject = {
      name: data.name,
      lat: data.lat,
      lng: data.lng,
      user_id: user.id.toString(),
      radius: data.radius,
      account_id: accountId ? accountId.toString() : user.account_id.toString(),
      building_type: data.type,
      surface_ha: data.surface,
    } as SiteCreate
    try {
      setFormPending(true)
      if (site) {
        if (!site.id) return
        const updatedSite = await sitesUpdate(site.id.toString(), { ...dataObject, id: site.id.toString() })
        setSites((prevSites) => {
          return prevSites.map((s) => (s.id === site.id ? updatedSite : s))
        })
        toast({
          title: t(i18nKeys.global.settings.page.sites.sheet.edit.toast.success),
        })
      } else {
        const createdSite = await sitesCreate(dataObject)
        setSites([...sites, createdSite])
        toast({
          title: t(i18nKeys.global.settings.page.sites.sheet.add.toast.success),
        })
      }

      setOpen(false)
    } catch (error) {
      if (isAxiosError(error) && error.response?.data) {
        toast({
          title: site
            ? t(i18nKeys.global.settings.page.sites.sheet.edit.toast.error)
            : t(i18nKeys.global.settings.page.sites.sheet.add.toast.error),
          description: error.response.data[0],
        })
      }
    } finally {
      setFormPending(false)
    }
  }
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel> {t(i18nKeys.global.settings.page.sites.label.name)}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="lat"
          render={({ field }) => (
            <FormItem>
              <FormLabel> {t(i18nKeys.global.settings.page.sites.label.lat)}</FormLabel>
              <FormControl>
                <Input {...field} type="number" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="lng"
          render={({ field }) => (
            <FormItem>
              <FormLabel> {t(i18nKeys.global.settings.page.sites.label.lng)}</FormLabel>
              <FormControl>
                <Input {...field} type="number" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <MapContainer center={[coordinates.lat, coordinates.lng]} zoom={13} zoomControl={false} className="h-24 w-full">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <MapComponent coordinates={coordinates} />
        </MapContainer>
        <FormField
          control={form.control}
          name="surface"
          render={({ field }) => (
            <FormItem>
              <FormLabel> {t(i18nKeys.global.settings.page.sites.label.surface)}</FormLabel>
              <FormControl>
                <Input {...field} type="number" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="type"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t(i18nKeys.global.settings.page.sites.label.type)}</FormLabel>
              <Select
                onValueChange={(e) => {
                  field.onChange(e)
                  onChangeType(e as BuildingTypeEnum)
                }}
                defaultValue={field.value}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {Object.entries(BuildingTypeEnum).map(([key, value]) => {
                    return (
                      <SelectItem key={key} value={value}>
                        {t(i18nKeys.global.settings.page.sites.buildingType[key])}
                      </SelectItem>
                    )
                  })}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="radius"
          render={({ field }) => (
            <FormItem>
              <FormLabel> {t(i18nKeys.global.settings.page.sites.label.radius)}</FormLabel>
              <FormControl>
                <Input type="number" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <SheetFooter>
          <Button type="submit" disabled={formPending}>
            {formPending && <Loader2 className="mr-2 size-4 animate-spin" />}
            {site ? t(i18nKeys.global.common.buttons.editSite) : t(i18nKeys.global.common.buttons.addSite)}
          </Button>
        </SheetFooter>
      </form>
    </Form>
  )
}

const MapComponent = ({ coordinates }) => {
  const map = useMap()

  useEffect(() => {
    if (coordinates.lat && coordinates.lng) {
      map.flyTo([coordinates.lat, coordinates.lng])
    }
  }, [coordinates, map])

  return <Circle center={[coordinates.lat, coordinates.lng]} radius={50} color={'cadetblue'} fillColor="cadetblue" />
}

export default SiteForm
