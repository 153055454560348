import { actionsTakenRetrieve } from '@/client/backend/api/actions-taken/actions-taken'
import { initiativesRetrieve } from '@/client/backend/api/initiatives/initiatives'
import { sitesRetrieve } from '@/client/backend/api/sites/sites'

export const initiativeDetailsLoader = async ({ params }) => {
  const { initiativeId, siteId, actionTakenId } = params

  const [site, initiative, actionTaken] = await Promise.all([
    siteId ? sitesRetrieve(siteId) : Promise.resolve(null),
    initiativesRetrieve(initiativeId),
    actionTakenId ? actionsTakenRetrieve(actionTakenId) : Promise.resolve(null),
  ])

  return { site, initiative, actionTaken }
}
