import { SensitiveAreaOut, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { LatLng } from 'leaflet'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import SiteProtectedZonesMap from '@/components/beeoimpact/impacts/site-protected-zones-map'
import { Icons } from '@/components/icons'
import RenderTooltip from '@/components/render-tooltip/render-tooltip'

interface ImpactsSummaryProps {
  protectedAreas: SensitiveAreaOut[]
  designatedAreas: SensitiveAreaOut[]
  site: Site
  center: LatLng
}
interface SubSpecies {
  name: string
  group: string
  iucn_category: string
  id: number
  protectedAreaId: number
}

const ImpactsSummary = ({ protectedAreas, center, designatedAreas, site }: ImpactsSummaryProps) => {
  const { t } = useTranslation()

  const biodivScore = (site?.properties?.stats_biodiversity?.biodiversity_score ?? 0).toFixed(2) + ' %'

  const speciesList = protectedAreas !== null ? protectedAreas.map((element) => element.speciesfound) : []

  const newArrayFlat = speciesList.flat(3)
  const speciesNames = [...new Set(newArrayFlat)]

  const designatedAreaNames = designatedAreas !== null ? designatedAreas.map((area) => area.name) : []

  const arraysNotEmpty = protectedAreas.length > 0 || designatedAreaNames.length > 0 || speciesNames.length > 0
  const biodivRadius = site?.properties?.biodiversity_radius

  const speciesCustomList = protectedAreas?.map((protectedArea) => {
    return [
      ...protectedArea.speciesfound.map((species) => {
        return {
          ...species,
          protectedAreaId: protectedArea.id,
        }
      }),
    ]
  })

  const speciesListFlat = speciesCustomList.flat(4)

  const keysToKeep = ['name', 'group', 'iucn_category', 'id', 'protectedAreaId']
  const subSpeciesList = speciesListFlat.map((obj) =>
    keysToKeep.reduce((acc, key) => {
      return { ...acc, [key]: obj[key] }
    }, {})
  ) as SubSpecies[]

  const keepUniqueSpeciesNames = (array: SubSpecies[]) => {
    return array.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i)
  }
  const uniqueSpecies = keepUniqueSpeciesNames(subSpeciesList).sort((a, b) => a.name.localeCompare(b.name))
  const iucnSpeciesSum = uniqueSpecies
    .map((c) => c.iucn_category)
    .filter((c) => c !== null && (c === 'CR' || c === 'NT' || c === 'VU' || c === 'EN' || c === 'LC')).length

  return (
    <div className="my-4 flex flex-row items-center justify-center space-y-4">
      {arraysNotEmpty &&
      !(
        protectedAreas.length === 0 &&
        designatedAreaNames.length === 0 &&
        speciesNames.length === 0 &&
        parseInt(biodivScore) !== 0
      ) ? (
        <>
          <div className="mt-4">
            {center && (
              <SiteProtectedZonesMap
                protectedAreas={protectedAreas}
                area={site.geometry}
                center={center}
                designatedAreas={designatedAreas}
              />
            )}
          </div>
          <Card className="min-h-[30rem] w-full rounded-r-md">
            <CardHeader className="flex flex-row  items-center justify-end rounded-r-md">
              <CardTitle className="flex items-center space-x-2 rounded-r-md">
                <div>{t(i18nKeys.beeoimpact.site.biodiversityAround.title)}</div>
                <RenderTooltip
                  size={24}
                  explanationText={t(i18nKeys.beeoimpact.site.biodiversityAround.titleDefinition)}
                />
              </CardTitle>
              <NavLink
                to={getPath(Routes.BEEOIMPACT_SITE_IMPACTS, { params: { siteId: site.id } })}
                className="ml-auto flex flex-row items-center justify-center"
              >
                <Button variant="link" size="sm" className="flex flex-row items-center">
                  {t(i18nKeys.beeoimpact.site.buttonDetails)}
                  <Icons.MoveRight className="ml-2 size-4" />
                </Button>
              </NavLink>
            </CardHeader>
            <CardContent className="overflow-auto rounded-r-md">
              <div className="flex flex-col justify-evenly space-y-4 overflow-auto rounded-r-md ">
                <div className="flex flex-row space-x-4">
                  <Icons.Leaf />
                  <div>
                    <p>{biodivScore}</p>
                    <p className="text-muted-foreground">
                      {t(i18nKeys.beeoimpact.site.biodiversityAround.biodiversityScore)}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row space-x-4">
                  <Icons.Trees />
                  <div>
                    <p>
                      {t(i18nKeys.beeoimpact.site.biodiversityAround.sensitiveAreasIn, {
                        count: site.properties?.stats_biodiversity?.nb_in_sensitive_area,
                      })}
                    </p>
                    <p className="text-muted-foreground">
                      {t(i18nKeys.beeoimpact.site.biodiversityAround.sensitiveAreaInDef)}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row space-x-4">
                  <Icons.Shrub />
                  <div>
                    <p>
                      {t(i18nKeys.beeoimpact.site.biodiversityAround.influenceZoneAdj, {
                        count: site.properties?.stats_biodiversity?.nb_adjacent_sensitive_area,
                      })}
                    </p>
                    <p className="text-muted-foreground">
                      {t(i18nKeys.beeoimpact.site.biodiversityAround.influenceZoneAdjDef)}
                    </p>
                  </div>
                </div>

                <div className="flex flex-row space-x-4">
                  <Icons.TreeDeciduous />
                  <div>
                    <p>
                      {t(i18nKeys.beeoimpact.site.biodiversityAround.closestReserve)}{' '}
                      {site?.properties?.stats_biodiversity?.closest_reserve?.distance_m?.toFixed(2)} m
                    </p>
                    <p className="text-muted-foreground">
                      {t(i18nKeys.beeoimpact.site.biodiversityAround.closestReserveDef)}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row space-x-4">
                  <Icons.Squirrel />

                  <div>
                    <p>
                      {iucnSpeciesSum} {t(i18nKeys.beeoimpact.site.biodiversityAround.iucnRedListSpecies)}
                    </p>
                    <p className="text-muted-foreground">
                      {t(i18nKeys.beeoimpact.site.biodiversityAround.iucnRedListSpeciesDef)}
                    </p>
                  </div>
                </div>
                {/* <div className="flex flex-row space-x-4">
                  <Icons.Divide />
                  <div >
                    <p >
                      {site?.properties?.stats?.percentage_of_sensitive_area.toFixed(2)} %{' '}
                      {t(i18nKeys.beeoimpact.site.biodiversityAround.ratioTotalSurface)}
                    </p>
                    <p className="text-muted-foreground">
                      {t(i18nKeys.beeoimpact.site.biodiversityAround.siteSensitiveAreaSurfaceRatioDef)}
                    </p>
                  </div>
                </div> */}
                {/* <div className="flex flex-row space-x-4">
                  <Icons.PercentCircle />
                  <div >
                    <p >
                      {site?.properties?.stats?.total_surface_in_sensitive_area_percentage.toFixed(2)} %{' '}
                      {t(i18nKeys.beeoimpact.site.biodiversityAround.ratioTotalSurfaceIn)}
                    </p>
                    <p className="text-muted-foreground">
                      {t(i18nKeys.beeoimpact.site.biodiversityAround.siteSensitiveAreaSurfaceInRatioDef)}
                    </p>
                  </div>
                </div> */}
                {/* <div className="flex flex-row space-x-4">
                  <Icons.PercentSquare />
                  <div >
                    <p >
                      {site?.properties?.stats?.total_surface_adjacent_sensitive_area_percentage.toFixed(2)} %{' '}
                      {t(i18nKeys.beeoimpact.site.biodiversityAround.ratioSurfaceAdj)}
                    </p>
                    <p className="text-muted-foreground">
                      {t(i18nKeys.beeoimpact.site.biodiversityAround.influenceZoneAdjDef)}
                    </p>
                  </div>
                </div> */}
              </div>
            </CardContent>
          </Card>
        </>
      ) : (
        <div className="container mt-4 flex h-80 items-center justify-center rounded-md border border-dashed">
          <div className="mx-auto flex max-w-[420px] flex-col items-center justify-center text-center">
            <Icons.Leaf size={42} />
            <h3 className="mt-4 text-lg font-semibold">
              {t(i18nKeys.beeoimpact.site.biodiversityAround.noSensitiveAreasFound)}
            </h3>
            <div className="mb-4 mt-2 text-sm text-muted-foreground">
              <p className="w-max">
                {t(i18nKeys.beeoimpact.site.biodiversityAround.noSensitiveAreas)} {biodivRadius}{' '}
                {t(i18nKeys.beeoimpact.site.measurementUnit)}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ImpactsSummary
