import { PlotCulture, ProbablePesticide, Site, SiteGeometry } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { LatLng } from 'leaflet'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { SpeciesType } from '@/types/species-type'
import GlobalEcoscoreColor from '@/lib/global-ecoscore'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import SiteMap from '@/components/beeoimpact/pesticides/site-map'
import { Icons } from '@/components/icons'
import { SpeciesIcon } from '@/components/icons-components/active-matters-icons'
import RenderTooltip from '@/components/render-tooltip/render-tooltip'

interface RisksSummaryProps {
  area?: SiteGeometry
  selectedPoint: LatLng
  siteId?: number
  activeMatters: ProbablePesticide[]
  landplots: PlotCulture[]
  site: Site
}

const RisksSummary = ({ area, selectedPoint, siteId, activeMatters, landplots }: RisksSummaryProps) => {
  const { t } = useTranslation()

  const desiredOrderSpeciesTypes = [
    SpeciesType.Water,
    SpeciesType.Bees,
    SpeciesType.Birds,
    SpeciesType.Fishes,
    SpeciesType.Mammals,
    SpeciesType.Worms,
    SpeciesType.Soil,
  ]

  const globalEcoscoreText = (ecoscore) => {
    if (ecoscore === undefined) {
      return t(i18nKeys.beeoimpact.common.impactLevel.noData)
    }
    if (ecoscore > 50 && ecoscore < 71) {
      return t(i18nKeys.beeoimpact.common.impactLevel.low)
    }
    if (ecoscore > 20 && ecoscore < 51) {
      return t(i18nKeys.beeoimpact.common.impactLevel.medium)
    }
    if (ecoscore >= 0 && ecoscore < 21) {
      return t(i18nKeys.beeoimpact.common.impactLevel.high)
    }
  }

  return (
    <div className="my-8">
      {activeMatters.length !== 0 ? (
        <div className="flex size-full flex-row items-end justify-center">
          <div className="h-full min-h-96 w-[25rem]">
            <SiteMap landplots={landplots} area={area} selectedPoint={selectedPoint} />
          </div>
          <Card className="flex min-h-96  w-full flex-col ">
            <CardHeader className="flex flex-row items-center justify-end">
              <CardTitle className="flex items-center space-x-2 rounded-r-md">
                <div>{t(i18nKeys.beeoimpact.site.pesticidesStatesRisks.title)}</div>
                <RenderTooltip
                  size={24}
                  explanationText={t(i18nKeys.beeoimpact.site.pesticidesStatesRisks.titleDefinition)}
                />
              </CardTitle>
              <NavLink
                to={getPath(Routes.BEEOIMPACT_SITE_PESTICIDES, { params: { siteId: siteId as number } })}
                className="ml-auto"
              >
                <Button variant="link" size="sm" className="flex flex-row items-center justify-center space-x-1">
                  {t(i18nKeys.beeoimpact.site.buttonDetails)}
                  <Icons.MoveRight className="ml-2 size-4" />
                </Button>
              </NavLink>
            </CardHeader>
            <CardContent className="overflow-auto rounded-r-md">
              <div className="flex flex-col space-y-4 overflow-auto rounded-r-md ">
                {activeMatters.map((matter, i) => {
                  return (
                    <div className="flex flex-row justify-between space-x-4" key={i}>
                      <div className="flex flex-row items-center">
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button variant="outline" size="icon" className="border-none">
                                <GlobalEcoscoreColor ecoscore_global={matter.ecoscores?.ecoscore_global} />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>{globalEcoscoreText(matter.ecoscores?.ecoscore_global)}</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                        <div className="flex flex-row items-center">
                          <div className="ml-2 capitalize">{matter.name.toLowerCase()}</div>
                        </div>
                      </div>
                      <div>
                        <div className="flex items-center">
                          {desiredOrderSpeciesTypes.map((speciesType, index) => (
                            <div
                              className={`${index === desiredOrderSpeciesTypes.length - 1 ? 'mr-0' : 'mr-2'}`}
                              key={speciesType}
                            >
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger asChild>
                                    <Button variant="outline" size="icon" className="border-none">
                                      <SpeciesIcon value={matter.ecoscores[speciesType]} species={speciesType} />
                                    </Button>
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    <p>
                                      {t(i18nKeys.beeoimpact.common.species[speciesType])} -{' '}
                                      <GlobalEcoscoreColor ecoscore_global={matter.ecoscores[speciesType]} />
                                    </p>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </CardContent>
          </Card>
        </div>
      ) : (
        <>
          <div className="container flex h-80 shrink-0 items-center justify-center rounded-md border border-dashed">
            <div className="mx-auto flex max-w-[420px] flex-col items-center justify-center text-center">
              <Icons.FlaskConical size={42} />

              <h3 className="mt-4 text-lg font-semibold">
                {t(i18nKeys.beeoimpact.site.biodiversityAround.noPesticidesFound)}
              </h3>
              <div className="mb-4 mt-2 text-sm text-muted-foreground">
                <p className="w-max">{t(i18nKeys.beeoimpact.site.biodiversityAround.noPesticides)}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default RisksSummary
