import { Link } from 'react-router-dom'

import landscape from '../../../assets/landscape.png'

interface EvaluateRisksOpportunitiesCardProps {
  icon: React.ReactNode
  title: string
  description1: string
  description2: string
  buttonText: string
}

const EvaluateRisksOpportunitiesCard = ({
  icon,
  title,
  description1,
  description2,
  buttonText,
}: EvaluateRisksOpportunitiesCardProps) => {
  return (
    <div className="relative h-[212px] w-full">
      <img
        src={landscape}
        alt="evaluate risks opportunities card"
        className="size-full rounded-lg object-cover brightness-50"
      />
      <div className="absolute bottom-4 left-4 md:bottom-12">{icon}</div>
      <div className="absolute bottom-8 left-1/2 -translate-x-1/2 text-center text-white md:bottom-4 md:left-1/2 lg:bottom-8 lg:left-1/2 xl:bottom-8 xl:left-1/2 2xl:bottom-8 2xl:left-1/2">
        <p className="pb-2 sm:text-sm md:text-lg lg:text-lg xl:text-xl 2xl:text-xl">{title}</p>
        <p className="text-sm sm:text-xs lg:text-xs xl:text-sm 2xl:text-sm">{description1}</p>
        <p className="pb-2 text-sm sm:text-xs lg:text-xs xl:text-sm 2xl:text-sm">{description2}</p>
        <Link
          className="mx-auto mt-4 flex h-[40px] w-[230px] items-center justify-center rounded-lg border border-white font-semibold text-white hover:bg-white hover:text-black "
          to={'mailto:info@beeodiversity.com?Subject=I would like to discover BeeOimpact'}
        >
          {buttonText}
        </Link>
      </div>
    </div>
  )
}

export default EvaluateRisksOpportunitiesCard
