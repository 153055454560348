import {
  accountsRetrieve,
  accountsStatsPesticidesRetrieve,
  accountsStatsRetrieve,
} from '@/client/backend/api/accounts/accounts'
import { sitesList } from '@/client/backend/api/sites/sites'
import { usersMeRetrieve } from '@/client/backend/api/users/users'
import { SitesListParams } from '@/client/backend/models'

import { FeatureType } from '@/types/feature-type'
import { hasAccesTo } from '@/lib/permission'

const dashboardLoader = async ({ request }) => {
  const queryParams = new URL(request.url).searchParams
  const page = queryParams.get('page')
  const page_size = queryParams.get('page_size')
  const ordering = queryParams.get('ordering')
  const search = queryParams.get('search')

  const user = await usersMeRetrieve()

  let params: SitesListParams = {
    page: page ? parseInt(page) : 1,
    page_size: page_size ? parseInt(page_size) : 10,
  }

  if (ordering) {
    params = { ...params, ordering }
  } else {
    params = { ...params, ordering: '-stats_biodiversity__nb_total_threatened_species' }
  }

  if (search) {
    params = { ...params, search: search, page: 1 }
  }

  const [account, paginatedSites] = await Promise.all([accountsRetrieve(user.account_id), sitesList(params)])

  const hasBiodivesityAccess = hasAccesTo(user.features, FeatureType.BIODIVERSITY)
  const hasPesticidesAccess = hasAccesTo(user.features, FeatureType.PESTICIDES)

  if (hasBiodivesityAccess && hasPesticidesAccess) {
    const [stats, statsPesticides] = await Promise.all([
      accountsStatsRetrieve(user.account_id),
      accountsStatsPesticidesRetrieve(user.account_id),
    ])
    return { account, paginatedSites, stats, statsPesticides }
  }

  if (hasBiodivesityAccess) {
    const stats = await accountsStatsRetrieve(user.account_id)
    return { account, paginatedSites, stats }
  }

  if (hasPesticidesAccess) {
    const statsPesticides = await accountsStatsPesticidesRetrieve(user.account_id)
    return { account, paginatedSites, statsPesticides }
  }
}

export default dashboardLoader
