import { useEffect } from 'react'
import ModuleRoute from '@/guards/module-route'
import PrivateRoute from '@/guards/private-route'
import ProtectedRoute from '@/guards/protected-route'
import AccountsPage from '@/pages/admin/accounts'
import accountsLoader from '@/pages/admin/accounts.loader'
import DataVisualisation from '@/pages/admin/data-visualisation'
import dataVisualisationLoader from '@/pages/admin/data-visualisation.loader'
import MappingsPage from '@/pages/admin/mappings'
import AdminSites from '@/pages/admin/sites'
import accountSitesLoader from '@/pages/admin/sites.loader'
import AdminUsers from '@/pages/admin/users'
import adminUsersLoader from '@/pages/admin/users.loader'
import ActivateUserPage from '@/pages/auth/activate-user'
import ForgotPasswordPage from '@/pages/auth/forgot-password'
import Login from '@/pages/auth/login'
import ResetPasswordPage from '@/pages/auth/reset-password'
import SiteDetailsAuditPage from '@/pages/beeoaudit/sites/site-details/site-details'
import SitesAuditPage from '@/pages/beeoaudit/sites/sites'
import DashboardPage from '@/pages/beeoimpact/dashboard/dashboard'
import dashboardLoader from '@/pages/beeoimpact/dashboard/dashboard.loader'
import GeneralAnalysisPage from '@/pages/beeoimpact/general-analysis/general-analysis'
import generalAnalysisLoader from '@/pages/beeoimpact/general-analysis/general-analysis.loader'
import ImpactsTablesPage from '@/pages/beeoimpact/impacts-tables/impacts-tables-page'
import impactsTableLoader from '@/pages/beeoimpact/impacts-tables/impacts-tables-page.loader'
import PesticidesDetailsPage from '@/pages/beeoimpact/pesticides-details/pesticides-details-page'
import { pesticidesLoader } from '@/pages/beeoimpact/pesticides-details/pesticides-details-page.loader'
import SiteSummaryPage from '@/pages/beeoimpact/site-summary/site-summary-page'
import siteSummaryLoader from '@/pages/beeoimpact/site-summary/site-summary-page.loader'
import Sites from '@/pages/beeoimpact/sites/sites'
import InitiativeDashboardPage from '@/pages/beeoinitiative/dashboard/dashboard'
import initiativeDashboardLoader from '@/pages/beeoinitiative/dashboard/dashboard.loader'
import InitiativeDetailsPage from '@/pages/beeoinitiative/initiative-details/initiative-details-page'
import { initiativeDetailsLoader } from '@/pages/beeoinitiative/initiative-details/initiative-details-page.loader'
import InitiativesPage from '@/pages/beeoinitiative/initiatives/initiatives'
import initiativeLoader from '@/pages/beeoinitiative/initiatives/initiatives.loader'
import NewInitiativePage from '@/pages/beeoinitiative/new-initiative/new-initiative'
import { newInitiativeLoader } from '@/pages/beeoinitiative/new-initiative/new-initiative.loader'
import TakenInitiativePage from '@/pages/beeoinitiative/site-detail/site-detail'
import { takenInitiativeLoader } from '@/pages/beeoinitiative/site-detail/site-detail.loader'
import InitiativeSitesPage from '@/pages/beeoinitiative/sites/initiative-sites'
import initiativeSiteLoader from '@/pages/beeoinitiative/sites/initiative-sites.loader'
import BiodiversityMonitoringPage from '@/pages/beeomonitoring/sites/site-detail/biodiversity/biodiversity'
import MetalsMonitoringPage from '@/pages/beeomonitoring/sites/site-detail/metals/metals'
import OtherPolluantsMonitoringPage from '@/pages/beeomonitoring/sites/site-detail/other-polluants/other-polluants'
import PesticidesMonitoringPage from '@/pages/beeomonitoring/sites/site-detail/pesticides/pesticides'
import SiteDetailMonitoringPage from '@/pages/beeomonitoring/sites/site-detail/site-detail'
import SitesMonitoringPage from '@/pages/beeomonitoring/sites/sites'
import ErrorBoundary from '@/pages/error-boundary'
import Page403 from '@/pages/errors/403'
import Page404 from '@/pages/errors/404'
import Page503 from '@/pages/errors/503'
import LandingPage from '@/pages/landing-page/landing-page'
import MethodologyPage from '@/pages/methodology'
import Profile from '@/pages/settings/profile'
import Security from '@/pages/settings/security'
import SitesSettings from '@/pages/settings/sites'
import { sitesSettingsLoader } from '@/pages/settings/sites.loader'
import Users from '@/pages/settings/users'
import { usersLoader } from '@/pages/settings/users.loader'
import { createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom'

import { FeatureType } from '@/types/feature-type'
import { Group } from '@/types/group'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import useMixpanel from '@/hooks/useMixpanel'
import AdminLayout from '@/components/layout/admin-layout'
import AuthLayout from '@/components/layout/auth-layout'
import Layout from '@/components/layout/layout'
import SettingsLayout from '@/components/layout/settings-layout'
import Loader from '@/components/loader'

const beeomonitoringRoute = [
  { path: getPath(Routes.BEEOMONITORING_SITES), element: <SitesMonitoringPage /> },
  {
    path: getPath(Routes.BEEOMONITORING_SITE),
    element: <SiteDetailMonitoringPage />,
  },
  {
    path: getPath(Routes.BEEOMONITORING_SITE_BIODIVERSITY),
    element: <BiodiversityMonitoringPage />,
  },
  {
    path: getPath(Routes.BEEOMONITORING_SITE_PESTICIDES),
    element: <PesticidesMonitoringPage />,
  },
  {
    path: getPath(Routes.BEEOMONITORING_SITE_METALS),
    element: <MetalsMonitoringPage />,
  },
  { path: getPath(Routes.BEEOMONITORING_SITE_OTHER_POLLUANTS), element: <OtherPolluantsMonitoringPage /> },
]
const beeoauditRoute = [
  { path: getPath(Routes.BEEOAUDIT_SITES), element: <SitesAuditPage /> },
  { path: getPath(Routes.BEEOAUDIT_SITE), element: <SiteDetailsAuditPage /> },
]

const beeoimpactRoute = [
  { path: getPath(Routes.BEEOIMPACT_DASHBOARD), element: <DashboardPage />, loader: dashboardLoader },
  { path: getPath(Routes.BEEOIMPACT_SITES), element: <Sites /> },
  { path: getPath(Routes.BEEOIMPACT_SITE), element: <SiteSummaryPage />, loader: siteSummaryLoader },
  { path: getPath(Routes.BEEOIMPACT_SITE_IMPACTS), element: <ImpactsTablesPage />, loader: impactsTableLoader },
  { path: getPath(Routes.BEEOIMPACT_SITE_PESTICIDES), element: <PesticidesDetailsPage />, loader: pesticidesLoader },
  {
    path: getPath(Routes.BEEOIMPACT_SITE_GENERAL_ANALYSIS),
    element: <ModuleRoute features={[FeatureType.POTENTIAL]} />,
    children: [{ index: true, element: <GeneralAnalysisPage />, loader: generalAnalysisLoader }],
  },
  { path: getPath(Routes.BEEOIMPACT_METHODOLOGY), element: <MethodologyPage /> },
]
const beeoinitiativeRoute = [
  {
    path: getPath(Routes.BEEOINITIATIVE_DASHBOARD),
    element: <InitiativeDashboardPage />,
    loader: initiativeDashboardLoader,
  },
  { path: getPath(Routes.BEEOINITIATIVE_SITES), element: <InitiativeSitesPage />, loader: initiativeSiteLoader },
  { path: getPath(Routes.BEEOINITIATIVE_SITE), element: <TakenInitiativePage />, loader: takenInitiativeLoader },
  { path: getPath(Routes.BEEOINITIATIVE_INITIATIVES), element: <InitiativesPage />, loader: initiativeLoader },
  {
    path: getPath(Routes.BEEOINITIATIVE_INITIATIVE_DETAIL_BY_SITE_BY_INITIATIVE),
    element: <InitiativeDetailsPage />,
    loader: initiativeDetailsLoader,
  },
  {
    path: getPath(Routes.BEEOINITIATIVE_INITIATIVE_DETAIL_BY_SITE_BY_INITIATIVE_BY_ACTIONTAKEN),
    element: <InitiativeDetailsPage />,
    loader: initiativeDetailsLoader,
  },
  {
    path: getPath(Routes.BEEOINITIATIVE_INITIATIVE_DETAIL_BY_INITIATIVE),
    element: <InitiativeDetailsPage />,
    loader: initiativeDetailsLoader,
  },
  {
    path: getPath(Routes.BEEOINITIATIVE_NEW_INITIATIVE_BY_SITE),
    element: <NewInitiativePage />,
    loader: newInitiativeLoader,
  },
  { path: getPath(Routes.BEEOINITIATIVE_INITIATIVES_BY_SITE), element: <InitiativesPage />, loader: initiativeLoader },
]
const adminRoute = [
  {
    element: <AdminLayout />,
    children: [
      { index: true, element: <AccountsPage />, loader: accountsLoader },
      { path: getPath(Routes.ADMIN_ACCOUNTS), element: <AccountsPage />, loader: accountsLoader },
      { path: getPath(Routes.ADMIN_ACCOUNTS_USERS), element: <AdminUsers />, loader: adminUsersLoader },
      { path: getPath(Routes.ADMIN_ACCOUNTS_SITES), element: <AdminSites />, loader: accountSitesLoader },
      { path: getPath(Routes.ADMIN_MAPPING), element: <MappingsPage /> },
      {
        path: getPath(Routes.ADMIN_DATA_VISUALISATION),
        element: <DataVisualisation />,
        loader: dataVisualisationLoader,
      },
    ],
  },
]
const settingsRoute = [
  { path: getPath(Routes.PROFILE), element: <Profile /> },
  {
    path: getPath(Routes.ACCOUNT_USERS),
    element: <ProtectedRoute groups={[Group.BEEO_INTERNAL, Group.ACCOUNT_MANAGER]} />,
    children: [{ index: true, element: <Users />, loader: usersLoader }],
  },
  {
    path: getPath(Routes.ACCOUNT_SITES),
    element: <ProtectedRoute groups={[Group.BEEO_INTERNAL, Group.ACCOUNT_MANAGER]} />,
    children: [{ index: true, element: <SitesSettings />, loader: sitesSettingsLoader }],
  },
  { path: getPath(Routes.SECURITY), element: <Security /> },
]

const authRoute = [
  { path: getPath(Routes.AUTH_LOGIN), element: <Login /> },
  { path: getPath(Routes.AUTH_FORGOT_PASSWORD), element: <ForgotPasswordPage /> },
  { path: getPath(Routes.AUTH_RESET_PASSWORD), element: <ResetPasswordPage /> },
  { path: getPath(Routes.AUTH_ACTIVATE_USER), element: <ActivateUserPage /> },
]
const errorsRoutes = [
  { path: getPath(Routes.ERROR_403), element: <Page403 /> },
  { path: getPath(Routes.ERROR_404), element: <Page404 /> },
  { path: getPath(Routes.ERROR_503), element: <Page503 /> },
]

const LayoutWithEvents = (props) => {
  const { pathname } = useLocation()
  const { track, isInitialized } = useMixpanel()

  useEffect(() => {
    track('Page View', { page: location?.pathname })
  }, [pathname, isInitialized])

  return <Layout {...props} />
}

const router = createBrowserRouter([
  {
    path: getPath(Routes.LANDING_PAGE),
    element: <LayoutWithEvents />,
    errorElement: <ErrorBoundary />,
    children: [
      { index: true, element: <LandingPage /> },
      {
        id: 'app',
        element: <PrivateRoute />,
        children: [
          {
            path: getPath(Routes.BEEOIMPACT),
            element: <ModuleRoute features={[FeatureType.PESTICIDES, FeatureType.BIODIVERSITY]} />,
            children: beeoimpactRoute,
          },
          {
            path: getPath(Routes.BEEOINITIATIVE),
            element: <ModuleRoute features={[FeatureType.INITIATIVE]} />,
            children: beeoinitiativeRoute,
          },
          {
            path: getPath(Routes.BEEOMONITORING),
            element: <ModuleRoute features={[FeatureType.MONITORING]} />,
            children: beeomonitoringRoute,
          },
          {
            path: getPath(Routes.BEEOAUDIT),
            element: <PrivateRoute />,
            children: beeoauditRoute,
          },
          {
            path: getPath(Routes.ADMIN),
            element: <ProtectedRoute groups={[Group.BEEO_INTERNAL]} />,
            children: adminRoute,
          },
          {
            path: getPath(Routes.SETTINGS),
            element: <SettingsLayout />,
            children: settingsRoute,
          },

          { path: getPath(Routes.ERRORS), children: errorsRoutes },
        ],
      },
    ],
  },
  { element: <AuthLayout />, children: authRoute },
])

const Route = () => {
  return <RouterProvider router={router} fallbackElement={<Loader />} />
}

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose())
}

export default Route
