import Page403 from '@/pages/errors/403'
import Page404 from '@/pages/errors/404'
import Page503 from '@/pages/errors/503'
import { isAxiosError } from 'axios'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'

import Error from '@/components/error'

const ErrorBoundary = () => {
  const error = useRouteError()

  let status = 500

  if (isRouteErrorResponse(error)) {
    status = error.status
  } else if (isAxiosError(error)) {
    status = error.request.status
  }

  if (status === 404) {
    return <Page404 />
  }

  if (status === 401 || status === 403) {
    return <Page403 />
  }

  if (status === 503) {
    return <Page503 />
  }

  return <Error status={status} title="Unexpected Error" description="Sorry we are currently working on it" />
}

export default ErrorBoundary
