import { i18nKeys } from '@/locales/keys'
import { Listbox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function StatusListBox({ selectedStatus, setSelectedStatus }) {
  const { t } = useTranslation()
  return (
    <Listbox name="status" value={selectedStatus} onChange={setSelectedStatus}>
      <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-teal-500 focus:outline-none focus:ring-1 focus:ring-teal-500 sm:text-sm">
        <span className="block truncate">
          {selectedStatus === 'DONE'
            ? t(i18nKeys.beeoinitiative.newInitiative.done)
            : t(i18nKeys.beeoinitiative.newInitiative.toDo)}
        </span>
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </Listbox.Button>

      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
        <Listbox.Option
          key="PENDING"
          className={({ active }) =>
            classNames(
              active ? 'bg-teal-600 text-white' : 'text-gray-900',
              'relative cursor-default select-none py-2 pl-3 pr-9'
            )
          }
          value="PENDING"
        >
          {({ selected, active }) => (
            <>
              <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                {t(i18nKeys.beeoinitiative.newInitiative.toDo)}
              </span>

              {selected ? (
                <span
                  className={classNames(
                    active ? 'text-white' : 'text-teal-600',
                    'absolute inset-y-0 right-0 flex items-center pr-4'
                  )}
                >
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              ) : null}
            </>
          )}
        </Listbox.Option>

        <Listbox.Option
          key="DONE"
          className={({ active }) =>
            classNames(
              active ? 'bg-teal-600 text-white' : 'text-gray-900',
              'relative cursor-default select-none py-2 pl-3 pr-9'
            )
          }
          value="DONE"
        >
          {({ selected, active }) => (
            <>
              <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                {t(i18nKeys.beeoinitiative.newInitiative.done)}
              </span>

              {selected ? (
                <span
                  className={classNames(
                    active ? 'text-white' : 'text-teal-600',
                    'absolute inset-y-0 right-0 flex items-center pr-4'
                  )}
                >
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              ) : null}
            </>
          )}
        </Listbox.Option>
      </Listbox.Options>
    </Listbox>
  )
}

export default StatusListBox
