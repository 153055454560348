import { initiativesList } from '@/client/backend/api/initiatives/initiatives'
import { sitesResponsesList, sitesRetrieve } from '@/client/backend/api/sites/sites'

export const newInitiativeLoader = async ({ params }) => {
  const { siteId } = params
  const [site, initiatives, siteResponses] = await Promise.all([
    sitesRetrieve(siteId),
    initiativesList(),
    sitesResponsesList(siteId),
  ])
  return {
    site,
    initiatives,
    siteResponses,
  }
}
