import { Account, AccountStats, AccountStatsPesticides, PaginatedSiteList } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'
import { useLoaderData } from 'react-router'

import { FeatureType } from '@/types/feature-type'
import { hasAccesTo } from '@/lib/permission'
import { useAuth } from '@/hooks/useAuth'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import DashboardBiodiversity from '@/components/beeoimpact/dashboard-biodiversity'
import DashboardPesticides from '@/components/beeoimpact/dashboard-pesticides'
import Loader from '@/components/loader'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'

interface DashboardImpactPageLoaderData {
  paginatedSites: PaginatedSiteList
  account: Account
  stats?: AccountStats
  statsPesticides?: AccountStatsPesticides
}

const DashboardImpactPage = () => {
  const { t } = useTranslation()
  const { user } = useAuth()

  const { paginatedSites, account, stats, statsPesticides } = useLoaderData() as DashboardImpactPageLoaderData

  if (!paginatedSites.results || !account || !user) {
    return <Loader />
  }

  const fullAccess =
    hasAccesTo(user?.features ?? [], FeatureType.BIODIVERSITY) &&
    hasAccesTo(user?.features ?? [], FeatureType.PESTICIDES)

  const hasBiodiversityAccess = hasAccesTo(user?.features ?? [], FeatureType.BIODIVERSITY)
  const hasPesticidesAccess = hasAccesTo(user?.features ?? [], FeatureType.PESTICIDES)

  if (hasBiodiversityAccess && !stats) {
    return <Loader />
  }

  if (hasPesticidesAccess && !statsPesticides) {
    return <Loader />
  }

  return (
    <div className="container">
      <div className="flex-1 space-y-4 pt-6">
        <div className="flex items-center justify-between space-y-2">
          <PageHeader>
            <PageHeaderHeading>{t(i18nKeys.beeoimpact.dashboard.title.main)}</PageHeaderHeading>
          </PageHeader>
          <div className="flex items-center space-x-2">
            <Select>
              <SelectTrigger className="w-[180px]">
                <SelectValue defaultValue={2023} placeholder="2023" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="2023">2023</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>

        {fullAccess ? (
          <Tabs defaultValue="biodiversity">
            <TabsList className="mb-4">
              <TabsTrigger value="biodiversity">{t(i18nKeys.beeoimpact.dashboard.trigger.biodiv)}</TabsTrigger>
              <TabsTrigger value="pesticides">{t(i18nKeys.beeoimpact.dashboard.trigger.pesticides)}</TabsTrigger>
            </TabsList>
            <TabsContent value="biodiversity">
              <DashboardBiodiversity sites={paginatedSites} account={account} stats={stats as AccountStats} />
            </TabsContent>
            <TabsContent value="pesticides">
              <DashboardPesticides
                sites={paginatedSites.results.features ?? []}
                stats={statsPesticides as AccountStatsPesticides}
              />
            </TabsContent>
          </Tabs>
        ) : (
          (hasBiodiversityAccess && (
            <DashboardBiodiversity sites={paginatedSites} account={account} stats={stats as AccountStats} />
          )) ||
          (hasPesticidesAccess && (
            <DashboardPesticides
              sites={paginatedSites.results.features ?? []}
              stats={statsPesticides as AccountStatsPesticides}
            />
          ))
        )}
      </div>
    </div>
  )
}

export default DashboardImpactPage
