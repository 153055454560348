import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'

interface CardFullProps {
  title: string
  description: string
  value: number
  icon: React.ReactNode
}

export const CardFull = ({ title, description, value, icon }: CardFullProps) => {
  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">
          {title}
          <CardDescription>{description}</CardDescription>
        </CardTitle>
        <div className="text-sm">{icon}</div>
      </CardHeader>

      <CardContent>
        <div className="text-4xl font-bold">{value}</div>
      </CardContent>
    </Card>
  )
}
