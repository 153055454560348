import { Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { ZoneNamesColor } from '@/pages/beeoimpact/general-analysis/general-analysis'
import { ColumnDef } from '@tanstack/react-table'
import { t } from 'i18next'

import { DataTable } from '@/components/data-table/data-table'
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header'

interface ClcTableProps {
  data: ZoneNamesColor[]
  site: Site
}

const ClcTable = ({ data }: ClcTableProps) => {
  const columns: ColumnDef<ZoneNamesColor>[] = [
    {
      accessorKey: 'name',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.name)}
        />
      ),
      cell: ({ row }) => {
        return <span className="max-w-[500px] truncate font-medium">{row.original.name}</span>
      },
    },
    {
      accessorKey: 'zone',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.type)}
        />
      ),
      cell: ({ row }) => {
        return <span className="max-w-[500px] truncate font-medium">{row.original.zone}</span>
      },
    },
    {
      accessorKey: 'area',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.surface)}
        />
      ),
      cell: ({ row }) => {
        return <span className="max-w-[500px] truncate font-medium">{row.original.surface.toFixed(2)}</span>
      },
    },
    {
      accessorKey: 'percentage',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.surfacePercentage)}
        />
      ),
      cell: ({ row }) => {
        return <span className="max-w-[500px] truncate font-medium">{row.original.percentage.toFixed(2)}</span>
      },
    },
    {
      accessorKey: 'color',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.color)}
        />
      ),
      cell: ({ row }) => {
        return <div className={`size-4`} style={{ backgroundColor: row.original.color }}></div>
      },
    },
  ]
  return <DataTable columns={columns} data={data} filterIdColumn="name" />
}

export default ClcTable
