import { CLCPlot } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { GbifMarker } from '@/pages/beeoimpact/general-analysis/general-analysis'
import { t } from 'i18next'
import { LatLng } from 'leaflet'
import {
  Circle,
  LayerGroup,
  LayersControl,
  MapContainer,
  Marker,
  ScaleControl,
  TileLayer,
  Tooltip,
} from 'react-leaflet'

import { ExternalLinks } from '@/lib/constants/external-links'
import SiteLocationMap from '@/components/beeoimpact/impacts/site-location-map'
import { getCustomMapIcon } from '@/components/icons-components/map-icons'

interface MapProps {
  center: LatLng
  gbifMarkers: GbifMarker[]
  clc: CLCPlot[]
}

const ClcGbifMap = ({ center, gbifMarkers, clc }: MapProps) => {
  return (
    <MapContainer
      className="z-0 h-[30rem] rounded-md"
      center={center}
      zoom={13.9}
      zoomSnap={0.1}
      scrollWheelZoom={false}
      attributionControl={true}
    >
      <Circle center={center} color={'cadetblue'} fillColor="cadetblue" radius={1500} />
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution={`&copy; <a href="${ExternalLinks.Clc}">${t(
          i18nKeys.beeoimpact.site.attribution.clc
        )}</a> &copy; <a href="${ExternalLinks.Gbif}"> ${t(i18nKeys.beeoimpact.site.attribution.gbif)}</a>
           `}
      />
      <LayersControl position="topright">
        <LayersControl.Overlay checked name={t(i18nKeys.beeoimpact.site.layer.clc)}>
          <LayerGroup>
            {clc?.map((c) => {
              return <SiteLocationMap clc={c} key={c.id} />
            })}
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name={t(i18nKeys.beeoimpact.site.layer.gbif)}>
          <LayerGroup>
            {gbifMarkers.map((marker) => (
              <Marker
                position={marker.marker}
                interactive={true}
                icon={getCustomMapIcon({ category: marker.category })}
                key={marker.id}
                pane="markerPane"
                zIndexOffset={1000}
              >
                <Tooltip>{marker.name}</Tooltip>
              </Marker>
            ))}
          </LayerGroup>
        </LayersControl.Overlay>
      </LayersControl>
      <ScaleControl position="bottomleft" />
    </MapContainer>
  )
}

export default ClcGbifMap
