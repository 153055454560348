import { Audit, PaginatedSiteList } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import BreadcrumbComponent from '@/pages/beeoinitiative/breadcrumb-component'
import ComingSoon from '@/pages/beeoinitiative/dashboard/coming-soon-card'
import DashboardCard from '@/pages/beeoinitiative/dashboard/dashboard-card'
import InitiativesTaken from '@/pages/beeoinitiative/dashboard/initiatives-taken'
import MostActiveSites from '@/pages/beeoinitiative/dashboard/most-active-sites'
import PlannedInitiatives from '@/pages/beeoinitiative/dashboard/planned-initiatives'
import Reports from '@/pages/beeoinitiative/dashboard/reports'
import TakeAction from '@/pages/beeoinitiative/dashboard/take-action'
import { FileUp } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useLoaderData } from 'react-router'
import { Link } from 'react-router-dom'

import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import BudgetTotal from '@/components/ui/budget-total'
import { Button } from '@/components/ui/button'
import { Icons } from '@/components/icons'
import NoSitesMessage from '@/components/no-sites-message'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'

import IndicatorsDashboard from './indicators-dashboard'

export interface InitiativeNameType {
  initName: string
  date: string
  siteName: string
  category?: string
}

interface InitDashboardData {
  sites: PaginatedSiteList
  actionsTakenInitiatives: PaginatedSiteList
  audits: Audit[]
}

const InitDashboardPage = () => {
  const { t } = useTranslation()
  const { sites, actionsTakenInitiatives, audits } = useLoaderData() as Awaited<InitDashboardData>

  const numberOfTakenInitiatives = actionsTakenInitiatives?.results?.features?.reduce(
    (acc, site) => acc + (site.properties?.actions_taken?.filter((action) => action?.status === 'DONE').length ?? 0),
    0
  )

  const currentYear = new Date().getFullYear()

  const sumNumberOfPlannedInitiatives = actionsTakenInitiatives?.results?.features?.reduce(
    (acc, site) =>
      acc +
      (site.properties?.actions_taken?.filter(
        (action) => action.status === 'PENDING' && new Date(action.date_start ?? 0).getFullYear() === currentYear
      ).length ?? 0),
    0
  )

  const sitesWithoutActions =
    actionsTakenInitiatives?.results?.features &&
    actionsTakenInitiatives?.results?.features.filter((site) => site.properties?.actions_taken?.length === 0)

  return (
    <div className="mb-8 flex flex-row px-11">
      <div className="flex-1">
        <div className="flex items-center justify-between space-y-2 ">
          <div>
            <BreadcrumbComponent
              breadcrumbItems={[
                {
                  label: t(i18nKeys.beeoinitiative.dashboard.breadcrumb.beeoapps),
                  href: '/',
                },
                {
                  label: t(i18nKeys.beeoinitiative.dashboard.breadcrumb.beeoinitiative),
                  href: getPath(Routes.BEEOINITIATIVE),
                },
              ]}
              currentPage={t(i18nKeys.beeoinitiative.dashboard.mainTitle)}
            />
            <PageHeader>
              <PageHeaderHeading className="text-5xl tracking-normal ">
                {t(i18nKeys.beeoinitiative.dashboard.mainTitle)}
              </PageHeaderHeading>
            </PageHeader>
          </div>

          <div className="relative flex items-center gap-x-2 ">
            <div className="absolute bottom-10 left-14 w-full">
              <ComingSoon big={false} />
            </div>
            <div className="opacity-20 blur-[1px]">
              <Button
                variant="outline"
                className="relative flex w-[215px] items-center justify-center border border-black "
              >
                <FileUp className="absolute left-0 ml-4 mt-6 size-5 -translate-y-3" />
                <span className="ml-6 text-[13px] font-semibold">
                  {t(i18nKeys.beeoinitiative.dashboard.exportResults)}
                </span>
              </Button>
            </div>
          </div>
        </div>
        {sites?.results?.features?.length === 0 ? (
          <NoSitesMessage />
        ) : (
          <>
            <div className="space-y-5 overflow-hidden">
              <IndicatorsDashboard
                sites={sites?.results?.features ?? []}
                actionsTakenInitiatives={actionsTakenInitiatives}
              />
              <div className="grid grid-cols-2 gap-5 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2">
                <div className="grid grid-cols-2 gap-5 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1">
                  <div className="grid grid-cols-2 gap-5 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2">
                    <DashboardCard
                      height="max-h-[330px]"
                      title={t(i18nKeys.beeoinitiative.dashboard.takeAction.title)}
                      children={<TakeAction actionsTakenInitiatives={actionsTakenInitiatives} />}
                      subtitle={
                        <p className="w-max space-x-1 text-sm">
                          <span className="font-bold text-biodivRedSevenHundred">
                            {sitesWithoutActions && sitesWithoutActions.length}
                          </span>
                          <span>{t(i18nKeys.beeoinitiative.dashboard.takeAction.subtitle)}</span>
                        </p>
                      }
                      footer={
                        <Link
                          to={getPath(Routes.BEEOINITIATIVE_SITES, { queryParams: { inactiveSites: true } })}
                          className="text-sm underline"
                        >
                          {t(i18nKeys.beeoinitiative.dashboard.takeAction.link)}
                        </Link>
                      }
                      showNumber={true}
                    />
                    <Reports audits={audits} />
                  </div>
                  <DashboardCard
                    title={t(i18nKeys.beeoinitiative.dashboard.threeCurrentObjectivesTitle)}
                    subtitle={t(i18nKeys.beeoinitiative.dashboard.threeCurrentObjectivesTitleSubtitle)}
                    children={<MostActiveSites actionsTakenInitiatives={actionsTakenInitiatives} />}
                    footer={
                      <Link to={getPath(Routes.BEEOINITIATIVE_SITES)}>
                        {t(i18nKeys.beeoinitiative.dashboard.allMySites)}
                      </Link>
                    }
                    footerAlignment="center"
                  />
                </div>
                <div className="grid grid-cols-2 gap-5 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1">
                  <DashboardCard
                    title={t(i18nKeys.beeoinitiative.dashboard.plannedInits.title)}
                    subtitle={t(i18nKeys.beeoinitiative.dashboard.plannedInits.subTitle)}
                    children={<PlannedInitiatives actionsTakenInitiatives={actionsTakenInitiatives} />}
                    numberOf={sumNumberOfPlannedInitiatives}
                    icon={<Icons.CalendarPlus className="size-8 text-biodivLimeFiveHundred" />}
                    opacity="opacity-20 blur-[1px]"
                    comingSoon={<ComingSoon big={true} cardBorder="border border-biodivVioletEightHundred" />}
                    border="border border-biodivVioletEightHundred"
                  />
                  <DashboardCard
                    title={t(i18nKeys.beeoinitiative.dashboard.takenInits.title)}
                    subtitle={t(i18nKeys.beeoinitiative.dashboard.takenInits.subtitle)}
                    children={<InitiativesTaken actionsTakenInitiatives={actionsTakenInitiatives} />}
                    numberOf={numberOfTakenInitiatives}
                  />
                  <DashboardCard
                    title={t(i18nKeys.beeoinitiative.dashboard.budget.title)}
                    subtitle={t(i18nKeys.beeoinitiative.dashboard.budget.subtitle)}
                    children={<BudgetTotal actionsTakenInitiatives={actionsTakenInitiatives} />}
                    bgColor="bg-amber-50"
                    footer={<Link to={'/'}>{t(i18nKeys.beeoinitiative.dashboard.manageBudget)}</Link>}
                    opacity="opacity-20 blur-[1px]"
                    comingSoon={<ComingSoon big={true} cardBorder="border border-biodivVioletEightHundred" />}
                    border="border border-biodivVioletEightHundred"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default InitDashboardPage
