import {
  sitesCddaList,
  sitesClcList,
  sitesLandplotsList,
  sitesN2000List,
  sitesRetrieve,
  sitesSpeciesList,
} from '@/client/backend/api/sites/sites'
import { usersMeRetrieve } from '@/client/backend/api/users/users'

import { FeatureType } from '@/types/feature-type'
import { hasAccesTo } from '@/lib/permission'

const siteSummaryLoader = async ({ params }) => {
  const { siteId } = params
  const site = await sitesRetrieve(siteId)

  const user = await usersMeRetrieve()

  const [landplots, clc, gbif, n2k, cdda] = await Promise.all([
    sitesLandplotsList(siteId),
    hasAccesTo(user.features, FeatureType.POTENTIAL) ? sitesClcList(siteId) : Promise.resolve([]),
    hasAccesTo(user.features, FeatureType.POTENTIAL) ? sitesSpeciesList(siteId) : Promise.resolve([]),
    sitesN2000List(siteId),
    sitesCddaList(siteId),
  ])

  return { site, n2k, cdda, landplots, clc, gbif }
}

export default siteSummaryLoader
