import { i18nKeys } from '@/locales/keys'

export const filterInitiativeOnSiteResponses = (initiative, siteResponses) => {
  let isIncluded = true
  if (initiative.outside) {
    isIncluded = siteResponses[0]?.answer === 'Oui'
  }
  if (initiative.inside && isIncluded) {
    isIncluded = siteResponses[1]?.answer === 'Oui'
  }
  return isIncluded
}

type InitiativeCategory = {
  readonly [key: string]: {
    labelKey: string
    color: string
    iconName?: string
  }
}

export const DEFAULT_INITIATIVE_CATEGORY = {
  labelKey: '',
  color: 'grey',
  iconName: null,
}

export const INITIATIVE_CATEGORIES: InitiativeCategory = {
  MANAGEMENT_BIODIVERSITY: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.initiativeCategory.managementBiodiversity,
    color: '#DF8700',
    iconName: 'snail',
  },
  GREEN_SPACES_MAINTENANCE: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.initiativeCategory.greenSpacesMaintenance,
    color: '#17938E',
    iconName: 'axe',
  },
  DEVELOPMENT: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.initiativeCategory.development,
    color: '#7943BB',
    iconName: 'house',
  },
  AWARENESS: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.initiativeCategory.awareness,
    color: '#3C73C7',
    iconName: 'volume-2',
  },
  AGRICULTURE: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.initiativeCategory.agriculture,
    color: '#1F6F6C',
    iconName: 'sprout',
  },
  IMPACT_REDUCTION: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.initiativeCategory.impactReduction,
    color: '#B91C1B',
    iconName: 'arrow-down-right',
  },
}

type Category = {
  readonly [key: string]: {
    labelKey: string
  }
}

export const SITE_TYPES: Category = {
  FORESTER: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.siteCategory.forestZone,
  },
  URBAN: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.siteCategory.urbanZone,
  },
  AGRICULTURAL: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.siteCategory.agriculturalZone,
  },
  HYBRID: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.siteCategory.mixedZone,
  },
}

export const SITE_CATEGORIES: Category = {
  1: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.siteCategory.urbanZone,
  },
  2: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.siteCategory.agriculturalZone,
  },
  3: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.siteCategory.forestZone,
  },
  4: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.siteCategory.mixedZone,
  },
}

export const IMPACTS: Category = {
  1: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.impact.lowImpact1,
  },
  2: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.impact.lowImpact2,
  },
  3: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.impact.moderateImpact,
  },
  4: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.impact.highImpact1,
  },
  5: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.impact.highImpact2,
  },
}

export const COSTS: Category = {
  1: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.cost.lowCost1,
  },
  2: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.cost.lowCost2,
  },
  3: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.cost.moderateCost,
  },
  4: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.cost.highCost1,
  },
  5: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.cost.highCost2,
  },
}

export const PERIODS: Category = {
  1: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.month.january,
  },
  2: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.month.february,
  },
  3: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.month.march,
  },
  4: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.month.april,
  },
  5: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.month.may,
  },
  6: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.month.june,
  },
  7: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.month.july,
  },
  8: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.month.august,
  },
  9: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.month.september,
  },
  10: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.month.october,
  },
  11: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.month.november,
  },
  12: {
    labelKey: i18nKeys.beeoinitiative.initiativesPage.month.december,
  },
}
