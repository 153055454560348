import { PlotCulture, SiteGeometry } from '@/client/backend/models'
import { LatLng } from 'leaflet'
import { Circle, MapContainer, ScaleControl, TileLayer } from 'react-leaflet'

import { DEFAULT_RADIUS, getBounds } from '@/lib/geo'
import { buildColors } from '@/lib/parcels'
import SiteLandPlot from '@/components/beeoimpact/pesticides/site-landplot'

interface SiteMapProps {
  area?: SiteGeometry
  landplots: PlotCulture[]
  selectedPoint: LatLng
}

const SiteMap = ({ area, landplots, selectedPoint }: SiteMapProps) => {
  const uniqueCulture = [
    ...new Set(
      landplots.map((landplot) => {
        return landplot.culture
      })
    ),
  ]

  const colors = buildColors(uniqueCulture)

  return (
    <MapContainer
      className="z-0 h-full min-h-96 w-[25rem] rounded-l-md"
      center={selectedPoint}
      bounds={getBounds(area?.coordinates?.[0])}
      scrollWheelZoom={false}
      attributionControl={false}
    >
      <TileLayer key="tile" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      {landplots?.map((landplot) => <SiteLandPlot key={landplot.id} landplot={landplot} colors={colors} />)}
      <Circle center={selectedPoint} radius={DEFAULT_RADIUS} color={'cadetblue'} fillColor="cadetblue" />
      <ScaleControl position="bottomleft" />
    </MapContainer>
  )
}

export default SiteMap
