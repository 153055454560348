import { useEffect, useState } from 'react'
import { PaginatedSiteList } from '@/client/backend/models'
import { MapPin } from 'lucide-react'
import { Label, PolarGrid, PolarRadiusAxis, RadialBar, RadialBarChart } from 'recharts'

import { ChartConfig, ChartContainer } from '@/components/ui/chart'

interface TakeActionProps {
  actionsTakenInitiatives: PaginatedSiteList
}
const TakeAction = ({ actionsTakenInitiatives }: TakeActionProps) => {
  const [showNumberInCircle, setShowNumberInCircle] = useState(innerWidth >= 1536)

  const sitesWithoutActions =
    actionsTakenInitiatives?.results?.features &&
    actionsTakenInitiatives?.results?.features.filter((site) => site.properties?.actions_taken?.length === 0)

  const percentageOfSitesWithoutActions = Math.floor(
    (sitesWithoutActions &&
      actionsTakenInitiatives?.results?.features &&
      (sitesWithoutActions.length / actionsTakenInitiatives?.results?.features.length) * 100) ||
      0
  )

  const chartData = [{ init: 13, fill: 'hsl(var(--biodiv-redSevenHundred))' }]

  const chartConfig = {
    init: {
      label: 'sites without initiative',
    },
  } satisfies ChartConfig

  const percentageAngle = 90 + (percentageOfSitesWithoutActions / 100) * 360

  useEffect(() => {
    const handleResize = () => {
      setShowNumberInCircle(window.innerWidth >= 1700)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <div className=" flex flex-row items-center justify-between space-x-12">
        {showNumberInCircle && <p className="text-6xl font-semibold">{percentageOfSitesWithoutActions}%</p>}
        <div className={`${showNumberInCircle ? '' : '  text-center'} `}>
          <div className="min-w-[120px]">
            <ChartContainer config={chartConfig} className="mx-auto aspect-square max-h-[120px]">
              <RadialBarChart
                data={chartData}
                startAngle={90}
                endAngle={percentageAngle}
                innerRadius={50}
                outerRadius={70}
              >
                <PolarGrid
                  gridType="circle"
                  radialLines={false}
                  stroke="none"
                  className="first:fill-muted last:fill-background"
                  polarRadius={[55, 46]}
                />
                <RadialBar dataKey="init" background cornerRadius={10} />
                <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
                  <Label
                    content={({ viewBox }) => {
                      if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                        return (
                          <g>
                            {showNumberInCircle ? (
                              <>
                                <MapPin
                                  x={viewBox.cx && viewBox.cx - 14}
                                  y={viewBox.cy && viewBox.cy - 25}
                                  className="text-ruby"
                                  width={30}
                                  height={36}
                                />
                                <text
                                  x={viewBox.cx}
                                  y={viewBox.cy && viewBox.cy + 40}
                                  textAnchor="middle"
                                  dominantBaseline="central"
                                  className="fill-current text-4xl font-semibold text-ruby"
                                ></text>
                              </>
                            ) : (
                              <text
                                x={viewBox.cx}
                                y={viewBox.cy}
                                textAnchor="middle"
                                dominantBaseline="central"
                                className="fill-current text-2xl font-semibold text-black"
                              >
                                {percentageOfSitesWithoutActions}%
                              </text>
                            )}
                          </g>
                        )
                      }
                      return null
                    }}
                  />
                </PolarRadiusAxis>
              </RadialBarChart>
            </ChartContainer>
          </div>
        </div>
      </div>
    </>
  )
}
export default TakeAction
