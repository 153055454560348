import { PaginatedSiteList } from '@/client/backend/models'
import { format, parseISO } from 'date-fns'

import { Badge } from '@/components/ui/badge'

interface PlannedInitiativesProps {
  actionsTakenInitiatives: PaginatedSiteList
}
const PlannedInitiatives = ({ actionsTakenInitiatives }: PlannedInitiativesProps) => {
  const currentYear = new Date().getFullYear()

  const plannedInitsDate = actionsTakenInitiatives?.results?.features?.map((site) =>
    site.properties?.actions_taken
      ?.filter((action) => new Date(action.date_start ?? 0).getFullYear() === currentYear)
      .map((action) => action.date_start)
      .flat()
  )

  const plannedInitsDateDefined = plannedInitsDate?.flat().filter((date) => date !== undefined)
  const monthsFirstLetter = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
  const monthsToColor = new Set(plannedInitsDateDefined?.map((date) => format(parseISO(date ?? ''), 'M')))
  return (
    <div className="flex flex-row space-x-2">
      {monthsFirstLetter.map((month, i) => (
        <Badge
          key={i}
          variant="outline"
          className={`flex size-6 items-center justify-center rounded-sm text-gray-400 ${monthsToColor.has((i + 1).toString()) ? 'bg-biodivTealSevenHundred text-white' : ''}`}
        >
          {month}
        </Badge>
      ))}
    </div>
  )
}
export default PlannedInitiatives
