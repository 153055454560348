import { useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { backendInstance } from '../../backend-instance'
import type { ErrorType } from '../../backend-instance'
import type {
  ActionTaken,
  ActionTakenRequest,
  Audit,
  BeeomonitoringSite,
  BeeomonitoringSitesSiteInfosRetrieve400,
  CLCPlot,
  PaginatedSiteList,
  PatchedSite,
  PlotCulture,
  Response,
  SensitiveAreaOut,
  Site,
  SiteCreate,
  SiteIdNameCenter,
  SitesActionsTakenCreateParams,
  SitesActionsTakenListParams,
  SitesClcList400,
  SitesCreate400,
  SitesListParams,
  SitesRetrieveParams,
  SitesUpdate400,
  SpeciesSample,
} from '../../models'

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>
}[keyof T]

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never

type Writable<T> = Pick<T, WritableKeys<T>>
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P]
    }
  : DistributeReadOnlyOverUnions<T>

export const beeomonitoringSitesSiteInfosRetrieve = (id: string, signal?: AbortSignal) => {
  return backendInstance<BeeomonitoringSite>({ url: `/beeomonitoring/sites/${id}/site_infos/`, method: 'GET', signal })
}

export const getBeeomonitoringSitesSiteInfosRetrieveQueryKey = (id: string) => {
  return [`/beeomonitoring/sites/${id}/site_infos/`] as const
}

export const getBeeomonitoringSitesSiteInfosRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof beeomonitoringSitesSiteInfosRetrieve>>,
  TError = ErrorType<BeeomonitoringSitesSiteInfosRetrieve400>,
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringSitesSiteInfosRetrieve>>, TError, TData>>
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getBeeomonitoringSitesSiteInfosRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof beeomonitoringSitesSiteInfosRetrieve>>> = ({ signal }) =>
    beeomonitoringSitesSiteInfosRetrieve(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof beeomonitoringSitesSiteInfosRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type BeeomonitoringSitesSiteInfosRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof beeomonitoringSitesSiteInfosRetrieve>>
>
export type BeeomonitoringSitesSiteInfosRetrieveQueryError = ErrorType<BeeomonitoringSitesSiteInfosRetrieve400>

export const useBeeomonitoringSitesSiteInfosRetrieve = <
  TData = Awaited<ReturnType<typeof beeomonitoringSitesSiteInfosRetrieve>>,
  TError = ErrorType<BeeomonitoringSitesSiteInfosRetrieve400>,
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringSitesSiteInfosRetrieve>>, TError, TData>>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBeeomonitoringSitesSiteInfosRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const sitesList = (params?: SitesListParams, signal?: AbortSignal) => {
  return backendInstance<PaginatedSiteList>({ url: `/sites/`, method: 'GET', params, signal })
}

export const getSitesListQueryKey = (params?: SitesListParams) => {
  return [`/sites/`, ...(params ? [params] : [])] as const
}

export const getSitesListQueryOptions = <TData = Awaited<ReturnType<typeof sitesList>>, TError = ErrorType<unknown>>(
  params?: SitesListParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesList>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesList>>> = ({ signal }) => sitesList(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesListQueryResult = NonNullable<Awaited<ReturnType<typeof sitesList>>>
export type SitesListQueryError = ErrorType<unknown>

export const useSitesList = <TData = Awaited<ReturnType<typeof sitesList>>, TError = ErrorType<unknown>>(
  params?: SitesListParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesList>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const sitesCreate = (siteCreate: SiteCreate) => {
  return backendInstance<Site>({
    url: `/sites/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: siteCreate,
  })
}

export const getSitesCreateMutationOptions = <TError = ErrorType<SitesCreate400>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof sitesCreate>>, TError, { data: SiteCreate }, TContext>
}): UseMutationOptions<Awaited<ReturnType<typeof sitesCreate>>, TError, { data: SiteCreate }, TContext> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof sitesCreate>>, { data: SiteCreate }> = (props) => {
    const { data } = props ?? {}

    return sitesCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof sitesCreate>>>
export type SitesCreateMutationBody = SiteCreate
export type SitesCreateMutationError = ErrorType<SitesCreate400>

export const useSitesCreate = <TError = ErrorType<SitesCreate400>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof sitesCreate>>, TError, { data: SiteCreate }, TContext>
}): UseMutationResult<Awaited<ReturnType<typeof sitesCreate>>, TError, { data: SiteCreate }, TContext> => {
  const mutationOptions = getSitesCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const sitesRetrieve = (id: string, params?: SitesRetrieveParams, signal?: AbortSignal) => {
  return backendInstance<Site>({ url: `/sites/${id}/`, method: 'GET', params, signal })
}

export const getSitesRetrieveQueryKey = (id: string, params?: SitesRetrieveParams) => {
  return [`/sites/${id}/`, ...(params ? [params] : [])] as const
}

export const getSitesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params?: SitesRetrieveParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesRetrieve>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesRetrieveQueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesRetrieve>>> = ({ signal }) =>
    sitesRetrieve(id, params, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof sitesRetrieve>>>
export type SitesRetrieveQueryError = ErrorType<unknown>

export const useSitesRetrieve = <TData = Awaited<ReturnType<typeof sitesRetrieve>>, TError = ErrorType<unknown>>(
  id: string,
  params?: SitesRetrieveParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesRetrieve>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesRetrieveQueryOptions(id, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const sitesUpdate = (id: string, siteCreate: SiteCreate) => {
  return backendInstance<Site>({
    url: `/sites/${id}/`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: siteCreate,
  })
}

export const getSitesUpdateMutationOptions = <TError = ErrorType<SitesUpdate400>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesUpdate>>,
    TError,
    { id: string; data: SiteCreate },
    TContext
  >
}): UseMutationOptions<Awaited<ReturnType<typeof sitesUpdate>>, TError, { id: string; data: SiteCreate }, TContext> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof sitesUpdate>>, { id: string; data: SiteCreate }> = (
    props
  ) => {
    const { id, data } = props ?? {}

    return sitesUpdate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof sitesUpdate>>>
export type SitesUpdateMutationBody = SiteCreate
export type SitesUpdateMutationError = ErrorType<SitesUpdate400>

export const useSitesUpdate = <TError = ErrorType<SitesUpdate400>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesUpdate>>,
    TError,
    { id: string; data: SiteCreate },
    TContext
  >
}): UseMutationResult<Awaited<ReturnType<typeof sitesUpdate>>, TError, { id: string; data: SiteCreate }, TContext> => {
  const mutationOptions = getSitesUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const sitesPartialUpdate = (id: string, patchedSite: NonReadonly<PatchedSite>) => {
  return backendInstance<Site>({
    url: `/sites/${id}/`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchedSite,
  })
}

export const getSitesPartialUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesPartialUpdate>>,
    TError,
    { id: string; data: NonReadonly<PatchedSite> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof sitesPartialUpdate>>,
  TError,
  { id: string; data: NonReadonly<PatchedSite> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sitesPartialUpdate>>,
    { id: string; data: NonReadonly<PatchedSite> }
  > = (props) => {
    const { id, data } = props ?? {}

    return sitesPartialUpdate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof sitesPartialUpdate>>>
export type SitesPartialUpdateMutationBody = NonReadonly<PatchedSite>
export type SitesPartialUpdateMutationError = ErrorType<unknown>

export const useSitesPartialUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesPartialUpdate>>,
    TError,
    { id: string; data: NonReadonly<PatchedSite> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof sitesPartialUpdate>>,
  TError,
  { id: string; data: NonReadonly<PatchedSite> },
  TContext
> => {
  const mutationOptions = getSitesPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const sitesDestroy = (id: string) => {
  return backendInstance<void>({ url: `/sites/${id}/`, method: 'DELETE' })
}

export const getSitesDestroyMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof sitesDestroy>>, TError, { id: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<typeof sitesDestroy>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof sitesDestroy>>, { id: string }> = (props) => {
    const { id } = props ?? {}

    return sitesDestroy(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof sitesDestroy>>>

export type SitesDestroyMutationError = ErrorType<unknown>

export const useSitesDestroy = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof sitesDestroy>>, TError, { id: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<typeof sitesDestroy>>, TError, { id: string }, TContext> => {
  const mutationOptions = getSitesDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}
export const sitesActionsTakenList = (id: string, params?: SitesActionsTakenListParams, signal?: AbortSignal) => {
  return backendInstance<ActionTaken[]>({ url: `/sites/${id}/actions-taken/`, method: 'GET', params, signal })
}

export const getSitesActionsTakenListQueryKey = (id: string, params?: SitesActionsTakenListParams) => {
  return [`/sites/${id}/actions-taken/`, ...(params ? [params] : [])] as const
}

export const getSitesActionsTakenListQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesActionsTakenList>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params?: SitesActionsTakenListParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesActionsTakenList>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesActionsTakenListQueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesActionsTakenList>>> = ({ signal }) =>
    sitesActionsTakenList(id, params, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesActionsTakenList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesActionsTakenListQueryResult = NonNullable<Awaited<ReturnType<typeof sitesActionsTakenList>>>
export type SitesActionsTakenListQueryError = ErrorType<unknown>

export const useSitesActionsTakenList = <
  TData = Awaited<ReturnType<typeof sitesActionsTakenList>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params?: SitesActionsTakenListParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesActionsTakenList>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesActionsTakenListQueryOptions(id, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const sitesActionsTakenCreate = (
  id: string,
  actionTakenRequest: ActionTakenRequest,
  params?: SitesActionsTakenCreateParams
) => {
  return backendInstance<ActionTaken[]>({
    url: `/sites/${id}/actions-taken/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: actionTakenRequest,
    params,
  })
}

export const getSitesActionsTakenCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesActionsTakenCreate>>,
    TError,
    { id: string; data: ActionTakenRequest; params?: SitesActionsTakenCreateParams },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof sitesActionsTakenCreate>>,
  TError,
  { id: string; data: ActionTakenRequest; params?: SitesActionsTakenCreateParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sitesActionsTakenCreate>>,
    { id: string; data: ActionTakenRequest; params?: SitesActionsTakenCreateParams }
  > = (props) => {
    const { id, data, params } = props ?? {}

    return sitesActionsTakenCreate(id, data, params)
  }

  return { mutationFn, ...mutationOptions }
}

export type SitesActionsTakenCreateMutationResult = NonNullable<Awaited<ReturnType<typeof sitesActionsTakenCreate>>>
export type SitesActionsTakenCreateMutationBody = ActionTakenRequest
export type SitesActionsTakenCreateMutationError = ErrorType<unknown>

export const useSitesActionsTakenCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sitesActionsTakenCreate>>,
    TError,
    { id: string; data: ActionTakenRequest; params?: SitesActionsTakenCreateParams },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof sitesActionsTakenCreate>>,
  TError,
  { id: string; data: ActionTakenRequest; params?: SitesActionsTakenCreateParams },
  TContext
> => {
  const mutationOptions = getSitesActionsTakenCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const sitesAuditsList = (id: string, signal?: AbortSignal) => {
  return backendInstance<Audit[]>({ url: `/sites/${id}/audits/`, method: 'GET', signal })
}

export const getSitesAuditsListQueryKey = (id: string) => {
  return [`/sites/${id}/audits/`] as const
}

export const getSitesAuditsListQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesAuditsList>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesAuditsList>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesAuditsListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesAuditsList>>> = ({ signal }) =>
    sitesAuditsList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesAuditsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesAuditsListQueryResult = NonNullable<Awaited<ReturnType<typeof sitesAuditsList>>>
export type SitesAuditsListQueryError = ErrorType<unknown>

export const useSitesAuditsList = <TData = Awaited<ReturnType<typeof sitesAuditsList>>, TError = ErrorType<unknown>>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesAuditsList>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesAuditsListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const sitesCddaList = (id: string, signal?: AbortSignal) => {
  return backendInstance<SensitiveAreaOut[]>({ url: `/sites/${id}/cdda/`, method: 'GET', signal })
}

export const getSitesCddaListQueryKey = (id: string) => {
  return [`/sites/${id}/cdda/`] as const
}

export const getSitesCddaListQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesCddaList>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesCddaList>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesCddaListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesCddaList>>> = ({ signal }) => sitesCddaList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesCddaList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesCddaListQueryResult = NonNullable<Awaited<ReturnType<typeof sitesCddaList>>>
export type SitesCddaListQueryError = ErrorType<unknown>

export const useSitesCddaList = <TData = Awaited<ReturnType<typeof sitesCddaList>>, TError = ErrorType<unknown>>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesCddaList>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesCddaListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Fetch plots around a site
 */
export const sitesClcList = (id: string, signal?: AbortSignal) => {
  return backendInstance<CLCPlot[]>({ url: `/sites/${id}/clc/`, method: 'GET', signal })
}

export const getSitesClcListQueryKey = (id: string) => {
  return [`/sites/${id}/clc/`] as const
}

export const getSitesClcListQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesClcList>>,
  TError = ErrorType<SitesClcList400>,
>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesClcList>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesClcListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesClcList>>> = ({ signal }) => sitesClcList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesClcList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesClcListQueryResult = NonNullable<Awaited<ReturnType<typeof sitesClcList>>>
export type SitesClcListQueryError = ErrorType<SitesClcList400>

export const useSitesClcList = <TData = Awaited<ReturnType<typeof sitesClcList>>, TError = ErrorType<SitesClcList400>>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesClcList>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesClcListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const sitesLandplotsList = (id: string, signal?: AbortSignal) => {
  return backendInstance<PlotCulture[]>({ url: `/sites/${id}/landplots/`, method: 'GET', signal })
}

export const getSitesLandplotsListQueryKey = (id: string) => {
  return [`/sites/${id}/landplots/`] as const
}

export const getSitesLandplotsListQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesLandplotsList>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesLandplotsList>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesLandplotsListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesLandplotsList>>> = ({ signal }) =>
    sitesLandplotsList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesLandplotsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesLandplotsListQueryResult = NonNullable<Awaited<ReturnType<typeof sitesLandplotsList>>>
export type SitesLandplotsListQueryError = ErrorType<unknown>

export const useSitesLandplotsList = <
  TData = Awaited<ReturnType<typeof sitesLandplotsList>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesLandplotsList>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesLandplotsListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const sitesN2000List = (id: string, signal?: AbortSignal) => {
  return backendInstance<SensitiveAreaOut[]>({ url: `/sites/${id}/n2000/`, method: 'GET', signal })
}

export const getSitesN2000ListQueryKey = (id: string) => {
  return [`/sites/${id}/n2000/`] as const
}

export const getSitesN2000ListQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesN2000List>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesN2000List>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesN2000ListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesN2000List>>> = ({ signal }) => sitesN2000List(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesN2000List>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesN2000ListQueryResult = NonNullable<Awaited<ReturnType<typeof sitesN2000List>>>
export type SitesN2000ListQueryError = ErrorType<unknown>

export const useSitesN2000List = <TData = Awaited<ReturnType<typeof sitesN2000List>>, TError = ErrorType<unknown>>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesN2000List>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesN2000ListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const sitesRecommendedActionsList = (id: string, signal?: AbortSignal) => {
  return backendInstance<ActionTaken[]>({ url: `/sites/${id}/recommended-actions/`, method: 'GET', signal })
}

export const getSitesRecommendedActionsListQueryKey = (id: string) => {
  return [`/sites/${id}/recommended-actions/`] as const
}

export const getSitesRecommendedActionsListQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesRecommendedActionsList>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesRecommendedActionsList>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesRecommendedActionsListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesRecommendedActionsList>>> = ({ signal }) =>
    sitesRecommendedActionsList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesRecommendedActionsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesRecommendedActionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof sitesRecommendedActionsList>>
>
export type SitesRecommendedActionsListQueryError = ErrorType<unknown>

export const useSitesRecommendedActionsList = <
  TData = Awaited<ReturnType<typeof sitesRecommendedActionsList>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesRecommendedActionsList>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesRecommendedActionsListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const sitesResponsesList = (id: string, signal?: AbortSignal) => {
  return backendInstance<Response[]>({ url: `/sites/${id}/responses/`, method: 'GET', signal })
}

export const getSitesResponsesListQueryKey = (id: string) => {
  return [`/sites/${id}/responses/`] as const
}

export const getSitesResponsesListQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesResponsesList>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesResponsesList>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesResponsesListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesResponsesList>>> = ({ signal }) =>
    sitesResponsesList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesResponsesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesResponsesListQueryResult = NonNullable<Awaited<ReturnType<typeof sitesResponsesList>>>
export type SitesResponsesListQueryError = ErrorType<unknown>

export const useSitesResponsesList = <
  TData = Awaited<ReturnType<typeof sitesResponsesList>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesResponsesList>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesResponsesListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const sitesSpeciesList = (id: string, signal?: AbortSignal) => {
  return backendInstance<SpeciesSample[]>({ url: `/sites/${id}/species/`, method: 'GET', signal })
}

export const getSitesSpeciesListQueryKey = (id: string) => {
  return [`/sites/${id}/species/`] as const
}

export const getSitesSpeciesListQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesSpeciesList>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesSpeciesList>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesSpeciesListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesSpeciesList>>> = ({ signal }) =>
    sitesSpeciesList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesSpeciesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesSpeciesListQueryResult = NonNullable<Awaited<ReturnType<typeof sitesSpeciesList>>>
export type SitesSpeciesListQueryError = ErrorType<unknown>

export const useSitesSpeciesList = <TData = Awaited<ReturnType<typeof sitesSpeciesList>>, TError = ErrorType<unknown>>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesSpeciesList>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesSpeciesListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const sitesIdNameCenterList = (signal?: AbortSignal) => {
  return backendInstance<SiteIdNameCenter[]>({ url: `/sites/id_name_center/`, method: 'GET', signal })
}

export const getSitesIdNameCenterListQueryKey = () => {
  return [`/sites/id_name_center/`] as const
}

export const getSitesIdNameCenterListQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesIdNameCenterList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesIdNameCenterList>>, TError, TData>>
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesIdNameCenterListQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesIdNameCenterList>>> = ({ signal }) =>
    sitesIdNameCenterList(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sitesIdNameCenterList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SitesIdNameCenterListQueryResult = NonNullable<Awaited<ReturnType<typeof sitesIdNameCenterList>>>
export type SitesIdNameCenterListQueryError = ErrorType<unknown>

export const useSitesIdNameCenterList = <
  TData = Awaited<ReturnType<typeof sitesIdNameCenterList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof sitesIdNameCenterList>>, TError, TData>>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesIdNameCenterListQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}
