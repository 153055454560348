import { Dispatch, SetStateAction } from 'react'
import { CultureMapping, PacCulture } from '@/client/backend/models'
import { ColumnDef } from '@tanstack/react-table'
import { Check } from 'lucide-react'

import { Checkbox } from '@/components/ui/checkbox'
import { DataTable } from '@/components/data-table/data-table'
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header'

type CulturePacTableProps = {
  data: PacCulture[]
  selectedPac: PacCulture[]
  setSelectedPac: Dispatch<SetStateAction<PacCulture[]>>
  mappings: CultureMapping[]
}

const CulturePacTable = ({ data, setSelectedPac, selectedPac, mappings }: CulturePacTableProps) => {
  const columns: ColumnDef<PacCulture>[] = [
    {
      id: 'select',
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
          className="translate-y-[2px]"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => {
            const isSelected = !!value
            const updatedSelectedPac = isSelected
              ? [...selectedPac, row.original]
              : selectedPac.filter((item) => item !== row.original)

            setSelectedPac(updatedSelectedPac)
            row.toggleSelected(isSelected)
          }}
          aria-label="Select row"
          className="translate-y-[2px]"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'id',
      header: ({ column }) => <DataTableColumnHeader column={column} children="" />,
      cell: ({ row }) => <div className="w-[80px]">{row.getValue('id')}</div>,
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'source_culture_name',
      id: 'source_culture_name',
      header: ({ column }) => <DataTableColumnHeader column={column} children="name" className="ml-4" />,
      cell: ({ row }) => {
        return (
          <div className="flex space-x-2">
            <span className="max-w-[500px] truncate font-medium">{row.getValue('source_culture_name')}</span>
          </div>
        )
      },
    },
    {
      id: 'isLinked',
      cell: ({ row }) => {
        const isInMappings = mappings.some((mapping) => mapping.pac_culture.id === row.original.id)
        return (
          <div className="flex space-x-2">
            <span className="min-w-[24px] max-w-[500px] truncate font-medium">
              {isInMappings ? <Check className="size-4 text-green-500" /> : null}
            </span>
          </div>
        )
      },
    },
  ]

  return <DataTable columns={columns} data={data} filterIdColumn="source_culture_name" className="min-h-[400px]" />
}

export default CulturePacTable
