import { AccountStatsPesticides, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { BarDatum, ResponsiveBar } from '@nivo/bar'
import { AlertTriangle, FlaskConical, MapPin } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { toTitleCase } from '@/lib/string'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { CardFull } from '@/components/card-full'
import NoSitesMessage from '@/components/no-sites-message'

interface DashboardPesticidesProps {
  sites: Site[]
  stats: AccountStatsPesticides
}
const DashboardPesticides = ({ sites, stats }: DashboardPesticidesProps) => {
  const { t } = useTranslation()

  const pesticides = stats.list_pesticides.sort((a, b) => b.occurrence - a.occurrence).slice(0, 10)
  const cultures = stats.list_culture.sort((a, b) => b.hectare - a.hectare).slice(0, 10)

  // put name of pesticides in pascal case
  pesticides.forEach((pesticide) => {
    pesticide.name = toTitleCase(pesticide.name)
  })

  return (
    <>
      {sites.length === 0 ? (
        <NoSitesMessage />
      ) : (
        <section>
          <div className="mb-4 grid gap-4 md:grid-cols-2 lg:grid-cols-4">
            <CardFull
              title={t(i18nKeys.beeoimpact.dashboard.pesticides.sites.title)}
              description={t(i18nKeys.beeoimpact.dashboard.pesticides.sites.description, { count: sites.length })}
              value={sites.length}
              icon={<MapPin className="size-4 text-muted-foreground" />}
            />
            <CardFull
              title={t(i18nKeys.beeoimpact.dashboard.pesticides.sitesAtRisk.title)}
              description={t(i18nKeys.beeoimpact.dashboard.pesticides.sitesAtRisk.description)}
              value={stats.nb_sites_at_risk}
              icon={<AlertTriangle className="size-4 text-muted-foreground" />}
            />
            <CardFull
              title={t(i18nKeys.beeoimpact.dashboard.pesticides.sitesWithPesticides.title)}
              description={t(i18nKeys.beeoimpact.dashboard.pesticides.sitesWithPesticides.description)}
              value={stats.list_pesticides.length}
              icon={<FlaskConical className="size-4 text-muted-foreground" />}
            />
          </div>
          <div className="my-8 flex flex-col items-center justify-center space-y-8 lg:flex-row lg:space-x-4 lg:space-y-0">
            <Card className="flex min-h-[80px] w-full min-w-0 flex-col lg:w-1/2">
              <CardHeader>
                <CardTitle> {t(i18nKeys.beeoimpact.dashboard.pesticides.mostPesticidesFound.title)}</CardTitle>
                <CardDescription>
                  {t(i18nKeys.beeoimpact.dashboard.pesticides.mostPesticidesFound.description, { count: sites.length })}
                </CardDescription>
              </CardHeader>
              <CardContent className="grid gap-1">
                <div style={{ height: '400px' }}>
                  <ResponsiveBar
                    data={pesticides as unknown as BarDatum[]}
                    keys={['occurrence']}
                    indexBy="name"
                    margin={{ top: 40, right: 100, bottom: 120, left: 60 }}
                    padding={0.3}
                    colorBy="indexValue"
                    colors={[
                      '#991b1b',
                      '#991b1b',
                      '#b91c1c',
                      '#b91c1c',
                      '#dc2626',
                      '#dc2626',
                      '#ef4444',
                      '#ef4444',
                      '#f87171',
                      '#f87171',
                    ]}
                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 90,
                    }}
                  />
                </div>
              </CardContent>
            </Card>
            <Card className="flex min-h-[80px] w-full min-w-0 flex-col lg:w-1/2">
              <CardHeader>
                <CardTitle> {t(i18nKeys.beeoimpact.dashboard.pesticides.mostCommonCultures.title)}</CardTitle>
                <CardDescription>
                  {t(i18nKeys.beeoimpact.dashboard.pesticides.mostCommonCultures.description, { count: sites.length })}
                </CardDescription>
              </CardHeader>
              <CardContent className="grid gap-1">
                <div style={{ height: '400px' }}>
                  <ResponsiveBar
                    data={cultures as unknown as BarDatum[]}
                    keys={['hectare']}
                    indexBy="name"
                    margin={{ top: 40, right: 100, bottom: 120, left: 60 }}
                    padding={0.3}
                    colorBy="indexValue"
                    colors={[
                      '#93c5fd',
                      '#93c5fd',
                      '#60a5fa',
                      '#60a5fa',
                      '#3b82f6',
                      '#3b82f6',
                      '#2563eb',
                      '#2563eb',
                      '#1d4ed8',
                      '#1d4ed8',
                    ]}
                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 90,
                    }}
                  />
                </div>
              </CardContent>
            </Card>
          </div>
        </section>
      )}
    </>
  )
}

export default DashboardPesticides
